import {Injectable, Injector, ProviderToken} from '@angular/core';

import {ModalHelperService} from '../../modal/modal-helper.service';
import {ResponsiveService} from '../../responsive/responsive.service';
import {ComponentInjectorService} from '../../util/component-injector.service';
import {ALERTS_READ_SPEED_FACTOR} from '../config-tokens';

import {ModalAlertOpener} from './modal-alert-opener';
import {SlidableAlertOpener} from './slidable-alert-opener';
import {Logger} from '../../logger/logger';

/**
 * Intanciate the appropiate alert opener depending on what platform is it in
 * use.
 */
@Injectable({providedIn: 'root'})
export class AlertOpenerFactory {
  private injector: Injector;

  constructor(
    parentInjector: Injector,
    private responsiveService: ResponsiveService,
  ) {
    this.injector = Injector.create({
      providers: [
        {
          provide: ModalAlertOpener,
          useClass: ModalAlertOpener,
          deps: [ModalHelperService, 'window'],
        },
        {
          provide: SlidableAlertOpener,
          useClass: SlidableAlertOpener,
          deps: [ALERTS_READ_SPEED_FACTOR, ComponentInjectorService, Logger],
        },
      ],
      parent: parentInjector,
    });
  }

  /**
   * Get the instance
   *
   * @return Instance of AlertOpener
   */
  getInstance() {
    return this.responsiveService.isDesktop()
      ? this.injector.get(SlidableAlertOpener)
      : this.injector.get(ModalAlertOpener);
  }

  get(
    type: ProviderToken<ModalAlertOpener | SlidableAlertOpener>,
  ): ModalAlertOpener | SlidableAlertOpener {
    return this.injector.get(type);
  }
}
