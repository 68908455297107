import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// import {environment} from '~environments/environment';

import {AbstractDao} from '../common/model/abstract.dao';

declare const dataLayer: DataLayer;

export interface DataLayer {
  push(data: any);
}

@Injectable({providedIn: 'root'})
export class MeasuringDao extends AbstractDao {
  constructor(private http: HttpClient) {
    super();
  }

  sendBackendEvent(name: string): Observable<unknown> {
    return this.http.get(this.baseUrl + '/users/events/shouldSend/' + name);
  }

  sendTagManagerEvent(name: string, options: {[x: string]: unknown} = {}) {
    if (typeof dataLayer === 'object') {
      dataLayer.push(Object.assign({event: name}, options));
    }
  }
}
