import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {first} from 'rxjs/operators';

import {ngModelProvider} from '../../model/ng-model-config';
import {AbstractNgModel} from '../abstract-ngmodel';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-wheel-selector',
  templateUrl: './wheel-selector.component.html',
  styleUrls: ['./wheel-selector.component.scss'],
  providers: [ngModelProvider(WheelSelectorComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelSelectorComponent
  extends AbstractNgModel<any>
  implements OnChanges, AfterContentInit
{
  @Input()
  valueSet: Array<any>;

  @Input('value')
  model: any;

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  allowEmpty = false;

  @Input()
  valueFn: (v: any | null) => any;

  @Input()
  disabled = false;

  /**
   * If true the wheel selector will not become visible by itself.
   */
  @Input()
  syncFadeIn = false;

  @Input()
  @HostBinding('class.fade')
  fadedOut = true;

  @Input()
  animationDuration = '.2s';

  @Output()
  changeSelection = new EventEmitter<any>();

  @ViewChild('wheel', {static: true})
  wheel: ElementRef;

  @ViewChild('firstItem')
  firstItem: ElementRef;

  @Output()
  ready = new EventEmitter<void>();

  /**
   * Index of the current selected item in the dataset.
   */
  valueIndex = 0;

  /**
   * Index of the displayed item, updates after animations to keep the dataset
   * in place. (virtual rolling)
   */
  displayIndex = 0;

  /**
   * Dataset used for display purposes, if allowEmpty is false this is the same
   * as the input dataset, with allowEmpty true it's the input dataset with a
   * null item prepended.
   */
  virtualSet: Array<any>;

  viewportHeight: string;

  childHeight: number;

  disabledButtons = false;

  /**
   * Ratio of the whole height in wich the next and previous items are shown at
   * the top and bottom.
   */
  readonly CORNER_RATIO = 0;

  private animating = false;

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.hasOwnProperty('valueSet') && this.valueSet) ||
      changes.hasOwnProperty('allowEmpty')
    ) {
      this.virtualSet = Array.from(this.valueSet);

      if (this.allowEmpty) {
        this.virtualSet.unshift(null);
      }
    }

    if (changes.hasOwnProperty('model')) {
      this.setValueSafe(changes['model'].currentValue);
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.childHeight = this.firstItem.nativeElement.offsetHeight;
      this.viewportHeight = `${this.childHeight * (1 + this.CORNER_RATIO * 2)}px`;
      this.fadedOut = this.syncFadeIn;
      this.cdr.markForCheck();
      this.ready.emit();
    });

    this.renderer.setStyle(
      this.wheel.nativeElement,
      'transform',
      `translate(0, -${40 - 20 * this.CORNER_RATIO}%)`,
    );
  }

  @HostListener('tlswipeup')
  next(): void {
    if (this.disabled) {
      return;
    }

    this.valueIndex =
      this.virtualSet.length - 1 < this.valueIndex + 1
        ? (this.valueIndex = 0)
        : this.valueIndex + 1;
    this.updatePosition(true);
    this.updateValue();
  }

  @HostListener('tlswipedown')
  previous(): void {
    if (this.disabled) {
      return;
    }

    this.valueIndex =
      0 > this.valueIndex - 1 ? this.virtualSet.length - 1 : this.valueIndex - 1;
    this.updatePosition(false);
    this.updateValue();
  }

  virtualIndex(n: number): number {
    if (n > this.virtualSet.length - 1) {
      return n - this.virtualSet.length;
    } else if (n < 0) {
      return this.virtualSet.length + n;
    } else {
      return n;
    }
  }

  writeValue(obj: any): void {
    if (obj === this.model) {
      return;
    }

    this.setValueSafe(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDisabledButtonState(isDisabled: boolean): void {
    this.disabledButtons = isDisabled;
    this.cdr.markForCheck();
  }

  private updatePosition(next: boolean): void {
    if (!this.wheel) {
      return;
    }

    this.renderer.setStyle(
      this.wheel.nativeElement,
      'transition',
      this.animationDuration,
    );
    let delta = (next ? 60 : 20) - 20 * this.CORNER_RATIO;
    this.renderer.setStyle(
      this.wheel.nativeElement,
      'transform',
      `translate(0, -${delta}%)`,
    );

    this.animating = true;
    fromEvent(this.wheel.nativeElement, 'transitionend')
      .pipe(first())
      .subscribe(() =>
        setTimeout(() => {
          this.displayIndex = this.valueIndex;
          this.cdr.markForCheck();
          this.animating = false;
          this.renderer.setStyle(this.wheel.nativeElement, 'transition', `0s`);
          this.renderer.setStyle(
            this.wheel.nativeElement,
            'transform',
            `translate(0, -${40 - 20 * this.CORNER_RATIO}%)`,
          );
        }),
      );
  }

  private updateValue(): void {
    this.model = this.extractValue(this.virtualSet[this.valueIndex]);

    this.modelChange(this.model);

    if (this.changeSelection.observed) {
      this.changeSelection.emit(this.model);
    }
  }

  /**
   * Ensure we have a set and the value is in it before changing the model.
   * If the value is not in the set, change the model to the default value.
   *
   * @param obj the value to set
   */
  private setValueSafe(obj: any): void {
    this.model = obj;
    if (!this.virtualSet) {
      return;
    }
    let found = this.virtualSet.findIndex(item => obj === this.extractValue(item));

    if (found >= 0) {
      this.valueIndex = found;

      if (!this.animating) {
        this.displayIndex = this.valueIndex;
        this.cdr.markForCheck();
      }
    } else {
      this.setDefaultvalue();
    }
  }

  private setDefaultvalue(): void {
    if (this.allowEmpty || !this.virtualSet) {
      this.model = null;
    } else {
      this.model = this.extractValue(this.virtualSet[0]);
    }

    this.valueIndex = 0;

    if (!this.animating) {
      this.displayIndex = this.valueIndex;
      this.cdr.markForCheck();
    }
  }

  private extractValue(obj: any): any {
    return this.valueFn ? this.valueFn(obj) : obj;
  }
}
