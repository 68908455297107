import {ComponentRef, Injectable, OnDestroy} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalOutlet} from '@angular/cdk/portal';
import {ToastComponent} from './toast.component';
import {TranslatableText} from '../i18n/translatable-text';
import {Destroyable} from '../util/destroyable';
import {first, Observable, Subject, takeUntil, timer} from 'rxjs';

@Injectable()
export class ToastService implements OnDestroy {
  private overlayRef: OverlayRef;

  @Destroyable()
  private destroy: Subject<void> = new Subject<void>();

  constructor(private overlay: Overlay) {}

  show(
    message: string | TranslatableText,
    duration?: number,
    destroy?: Subject<unknown> | Observable<unknown>,
    component: any = ToastComponent,
    componentParams?: Record<string, any>,
    portalOutlet?: PortalOutlet,
  ): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    if (portalOutlet && portalOutlet.hasAttached()) {
      portalOutlet.detach();
    }

    const toastPortal: ComponentPortal<any> = new ComponentPortal(component);
    let toastRef: ComponentRef<any>;

    if (portalOutlet) {
      toastRef = portalOutlet.attach(toastPortal);
    } else {
      this.overlayRef = this.createOverlay();
      toastRef = this.overlayRef.attach(toastPortal);
    }

    toastRef.instance.message = message;

    if (componentParams) {
      Object.keys(componentParams).forEach(
        key => (toastRef.instance[key] = componentParams[key]),
      );
    }

    if (duration) {
      timer(duration)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.close());
    }
    if (destroy) {
      destroy.subscribe(() => this.close(portalOutlet));
    }
  }

  isToastVisible(): boolean {
    return !!this.overlayRef && this.overlayRef.hasAttached();
  }

  close(portalOutlet?: PortalOutlet): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.destroyOverlay();
    }
    if (portalOutlet && portalOutlet.hasAttached()) {
      portalOutlet.detach();
    }
  }

  ngOnDestroy(): void {
    this.destroyOverlay();
  }

  private createOverlay(): OverlayRef {
    const overlayConfig: OverlayConfig = new OverlayConfig({
      hasBackdrop: false,
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
    return this.overlay.create(overlayConfig);
  }

  private destroyOverlay(): void {
    if (this.overlayRef) {
      timer(1000)
        .pipe(first())
        .subscribe(() => this.overlayRef.dispose());
    }
  }
}
