<tl-modal-dialog
  type="ok_only"
  [closeOnNavigation]="true"
  [title]="{
    key: 'geolocation.permissionDeniedWeb.title'
  }"
  [accept]="{key: 'global.understood'}">
  <ng-container *ngIf="!browserWithoutSteps">
    <tl-translatable-text
      [text]="{
        key: 'geolocation.permissionDeniedWeb.subtitle'
      }">
    </tl-translatable-text>
    <img
      class="tl-geolocation-blocked-modal__image"
      [src]="stepsImage"
      *ngIf="stepsImage" />
  </ng-container>

  <tl-translatable-text
    class="tl-geolocation-blocked-modal__permissions-denied"
    *ngIf="browserWithoutSteps"
    [text]="{
      key: 'geolocation.permissionDeniedWeb.unlockInstructionsFallback'
    }"></tl-translatable-text>
</tl-modal-dialog>
