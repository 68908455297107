import {ComponentRef, Inject, Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';

import {ComponentInjectorService} from '../../util/component-injector.service';
import {Alert} from '../alert';
import {AlertComponent} from '../alert-slidable/alert.component';
import {ALERTS_READ_SPEED_FACTOR} from '../config-tokens';

import {AlertOpener} from './alert-opener';
import {Logger} from '../../logger/logger';

/**
 * Open alerts in a thinner label, the alerts can be canceled by clicking the
 * button or autoclose if timeout is over.
 */
@Injectable()
export class SlidableAlertOpener extends AlertOpener {
  private lastAlert: ComponentRef<AlertComponent>;

  constructor(
    @Inject(ALERTS_READ_SPEED_FACTOR) private readSpeedFactor: number,
    private componentInjectorService: ComponentInjectorService,
    private logger: Logger,
  ) {
    super();
  }

  /**
   * @inheritDoc
   */
  open(alert: Alert): Observable<any> {
    if (alert.options && alert.options.callback) {
      alert.options.callback();
    }

    return new Observable((observer: Observer<any>) => {
      let currentAlert =
        this.componentInjectorService.appendComponent<AlertComponent>(
          AlertComponent,
          this.buildOptions(alert),
        );
      this.lastAlert = currentAlert;

      let alertTimer = setTimeout(() => {
        this.removeAlert(currentAlert);
        observer.next(true);
        observer.complete();
      }, this.getAlertTimeout(alert));

      currentAlert.instance.changeEvent.subscribe(() => {
        clearTimeout(alertTimer);
        this.removeAlert(currentAlert);
        observer.next(true);
        observer.complete();
      });
    });
  }

  closeLastAlert() {
    if (this.lastAlert) {
      this.removeAlert(this.lastAlert);
    }
  }

  private removeAlert(currentAlert: ComponentRef<AlertComponent>) {
    this.componentInjectorService.removeComponent(currentAlert);
    this.lastAlert = null;
  }

  // noinspection JSMethodCanBeStatic
  private buildOptions(alert: Alert) {
    return {message: alert.message, type: alert.type};
  }

  // noinspection JSMethodCanBeStatic
  private getAlertTimeout(alert: Alert) {
    if (alert.options?.customTimeout) {
      return alert.options.customTimeout;
    }
    // https://tulotero.atlassian.net/browse/WEB-3532
    try {
      const seed =
        typeof alert.message === 'string'
          ? alert.message.length
          : alert.message.key.length;
      return (seed * 1000) / this.readSpeedFactor;
    } catch (e) {
      this.logger.error('WEB-3532 - Error calculating alert timeout', e.stack, {
        alertData: alert,
      });
      return 10000;
    }
  }
}
