import {transition, trigger, useAnimation} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {isNumeric} from '../../util/core/number';
import {slideInVertical, slideOutVertical} from '../../animation/angular/slide';
import {TranslatableText} from '../../i18n/translatable-text';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-money-bar',
  templateUrl: './money-bar.component.html',
  styleUrls: ['./money-bar.component.scss'],
  animations: [
    trigger('ConfirmAnimation', [
      transition(':enter', [
        useAnimation(slideInVertical, {
          params: {start: '-100%', timing: '500ms ease'},
        }),
      ]),
      transition(':leave', [
        useAnimation(slideOutVertical, {
          params: {end: '-100%', timing: '500ms ease'},
        }),
      ]),
    ]),
    trigger('MoneyBarAnimation', [
      transition(':enter', [
        useAnimation(slideInVertical, {
          params: {start: '100%', timing: '500ms ease'},
        }),
      ]),
      transition(':leave', [
        useAnimation(slideOutVertical, {
          params: {end: '100%', timing: '500ms ease'},
        }),
      ]),
    ]),
  ],
})
export class MoneyBarComponent implements AfterViewInit {
  @Input()
  price1: number;

  @Input()
  integer1 = false;

  @Input()
  priceDesc1: string;

  @Input()
  price2: number;

  @Input()
  integer2 = false;

  @Input()
  priceDesc2: string;

  @Input()
  buttonText: string;

  @Input()
  inForm = false;

  @Input()
  confirm = false;

  @Input()
  disableConfirm = false;

  @Input()
  confirmText: string | TranslatableText;

  /**
   * Hooks that validates if confirm can activated
   *
   */
  @Input()
  beforeConfirm: (...args: any[]) => any;

  @Input()
  loading = false;

  @Input()
  disabledStyle = false;

  @Output()
  buttonClick = new EventEmitter<any>();

  confirmed = false;

  firstRender = true;

  @Input()
  nomargin = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.firstRender) {
      this.firstRender = false;
      this.cdr.detectChanges();
    }
  }

  @HostListener('document:keydown', ['$event'])
  preventEnter(e: KeyboardEvent): void {
    if (e.key && e.key === 'Enter') {
      e.preventDefault();
    }
  }

  onClick() {
    if ((this.confirm && this.confirmed) || !this.confirm) {
      this.buttonClick.emit();
    } else {
      if (this.beforeConfirm) {
        if (this.beforeConfirm()) {
          this.confirmed = true;
        }
      } else {
        this.confirmed = true;
      }
      return false;
    }
  }

  isNumeric(number: any) {
    return isNumeric(number);
  }

  isLongPrice(price: number) {
    let p = price.toString();
    let splitter: string;
    if (p.indexOf('.') >= 0) {
      splitter = '.';
    } else if (p.indexOf(',') >= 0) {
      splitter = ',';
    }

    if (splitter) {
      let chunks = p.split(splitter);
      return chunks[0].length > 4;
    } else {
      return p.length > 4;
    }
  }
}
