import {Injectable} from '@angular/core';

import {TranslatableText} from '../i18n/translatable-text';
import {Queue} from '../util/collections/queue';

import {Alert} from './alert';
import {AlertOpener} from './alert-opener/alert-opener';
import {AlertOpenerFactory} from './alert-opener/alert-opener-factory';
import {AlertOptions} from './alert-options';
import {ModalAlertOpener} from './alert-opener/modal-alert-opener';

@Injectable({providedIn: 'root'})
export class AlertsService {
  private alertsQueue = new Queue<Alert>();

  private currentRunningAlert: Alert | null = null;

  private queueRunning = false;

  constructor(private alertOpenerFactory: AlertOpenerFactory) {}

  notifySuccess(message: string | TranslatableText, options?: AlertOptions) {
    this.notify(new Alert(message, 'success', options));
  }

  notifyInfo(message: string | TranslatableText, options?: AlertOptions) {
    this.notify(new Alert(message, 'info', options));
  }

  notifyWarning(message: string | TranslatableText, options?: AlertOptions) {
    this.notify(new Alert(message, 'warning', options));
  }

  notifyWarningUser(message: string | TranslatableText, options?: AlertOptions) {
    this.notify(new Alert(message, 'warning-user', options));
  }

  notifyError(message: string | TranslatableText, options?: AlertOptions) {
    this.notify(new Alert(message, 'error', options));
  }

  closeLastAlert() {
    const opener: AlertOpener = this.alertOpenerFactory.getInstance();
    opener.closeLastAlert();
  }

  private notify(alert: Alert) {
    if (
      alert.options?.id &&
      (this.currentRunningAlert?.options?.id === alert.options.id ||
        this.alertsQueue.store.some(a => a.options.id === alert.options.id))
    ) {
      return;
    }
    this.alertsQueue.push(alert);
    if (!this.queueRunning) {
      this.proccessQueue();
    }
  }

  private proccessQueue() {
    this.queueRunning = true;

    let alert = this.alertsQueue.pop();
    let opener: AlertOpener;
    if (alert.options?.forceModal) {
      opener = this.alertOpenerFactory.get(ModalAlertOpener);
    } else {
      opener = this.alertOpenerFactory.getInstance();
    }

    this.currentRunningAlert = alert;
    opener.open(alert).subscribe(() => {
      this.currentRunningAlert = null;
      if (!this.alertsQueue.isEmpty()) {
        this.proccessQueue();
      } else {
        this.queueRunning = false;
      }
    });
  }
}
