import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '~environments/environment';
import {
  Destroyable,
  formatMinute,
  ModalDialogComponent,
  ResponsiveService,
  TranslatableText,
} from 'common';
import {BehaviorSubject, first, map, Subject, take, takeUntil, timer} from 'rxjs';
import {VerificationDao} from '../verification-dao';
import {ErrorService} from '../../../error/error.service';
import {UserService} from '../../../user/data/user.service';
import {VerificationScreen} from './verification-screen';
import {VerificationInfo} from './verification-info';
import {tap} from 'rxjs/operators';
import {differenceInSeconds} from 'date-fns';
import {maskPhoneNumber} from '../../../user/util/util';
import {MeasuringService} from '../../../marketing/measuring.service';

@Component({
  selector: 'tl-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VerificationDialogComponent implements OnInit {
  @Input()
  image: string;

  @Input()
  title: string | TranslatableText;

  @Input()
  info: VerificationInfo;

  phone: string;
  errorTimer: string | TranslatableText;

  buttonCallBack: (...args: any[]) => any;
  descriptionText: TranslatableText;
  sendButtonText: string | TranslatableText;
  confirmButtonAction = 'confirm';
  isLoading = false;
  submitted = false;
  showInformation = true;
  inputError = false;
  showDescriptionText = true;
  form: FormControl;
  wrongCode: BehaviorSubject<string | TranslatableText> = new BehaviorSubject('');

  screen: VerificationScreen;

  readonly routeMap = environment.locale.routes;

  @HostBinding('class.tl-verification-dialog')
  readonly hostClass = true;

  private readonly ID_VERIFICATION_INVALID_MAX_ATTEMPTS_REACHED =
    'ID_VERIFICATION_INVALID_MAX_ATTEMPTS_REACHED';

  @Destroyable()
  private destroySubject = new Subject<void>();

  get isMobile(): boolean {
    return this.responsiveService.isMobile();
  }

  get showForm(): boolean {
    return this.screen === VerificationScreen.INSERT_VERIFICATION_CODE;
  }

  get enableButton(): boolean {
    return this.screen !== VerificationScreen.REQUEST_CODE_FAILED;
  }

  get isDeviceBlocked(): boolean {
    return this.screen === VerificationScreen.INITIAL_SCREEN_ON_ERROR;
  }

  get hasActiveCode(): boolean {
    return (
      (!this.isLoading && VerificationScreen.INITIAL_SCREEN === this.screen) ||
      VerificationScreen.REQUEST_CODE_FAILED === this.screen
    );
  }

  get disableButton(): boolean {
    return (
      this.screen === VerificationScreen.INSERT_VERIFICATION_CODE &&
      (this.form.invalid || !!this.wrongCode.getValue())
    );
  }

  @ViewChild(ModalDialogComponent, {static: true})
  private dialog: ModalDialogComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private errorService: ErrorService,
    private responsiveService: ResponsiveService,
    private verificationDao: VerificationDao,
    private userService: UserService,
    private measurementDao: MeasuringService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userService
      .getData()
      .pipe(first())
      .subscribe(
        user => (this.phone = maskPhoneNumber(user.phone, user.phonePrefix)),
      );

    this.form = new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(5),
    ]);

    if (this.shouldShowInitialScreen()) {
      this.configureScreen(VerificationScreen.INITIAL_SCREEN);
    } else {
      this.configureScreen(VerificationScreen.INITIAL_SCREEN_ON_ERROR);
    }
  }

  /**
   * This method sends the code input by the user to confirm the request
   *
   * @returns boolean true if success and false if failure
   */
  confirmCode(): boolean {
    this.submitted = true;
    this.isLoading = true;

    if (this.form.invalid) {
      this.isLoading = false;
      return false;
    }

    this.verificationDao.confirmCode(this.form.value).subscribe({
      next: () => {
        // Stop the timer and reconfigure screen
        this.isLoading = false;
        this.submitted = false;
        this.configureScreen(VerificationScreen.CONFIRM_CODE_SUCCESS);
      },
      error: err => {
        // Show error and reconfigure screen if required
        const error = this.errorService.processErrorManually(err);
        if (error.status === this.ID_VERIFICATION_INVALID_MAX_ATTEMPTS_REACHED) {
          this.configureScreen(VerificationScreen.DEVICE_BLOCKED);
        } else if (error.message) {
          this.wrongCode.next(error.message);
          this.inputError = true;
          this.isLoading = false;
          this.submitted = false;
        }
      },
    });

    return true;
  }

  /**
   * Closes the modal and send the type to close method
   *
   * @param action string the type of action to implement
   */
  closeDialog(action: string): void {
    if (action === 'cancel') {
      this.dialog.dismiss(action);
    }
    this.dialog.close(action);
  }

  /**
   * Redirects to contact and closes the modal
   */
  goToContact(): void {
    this.router.navigateByUrl(
      !this.isMobile
        ? `/${this.routeMap.contactUs}`
        : `/m/${this.routeMap.contactUs}`,
    );
    this.closeDialog('cancel');
  }

  /**
   *
   * @returns boolean true if link needs to be shown
   */
  displayContactLink(): boolean {
    return this.descriptionText.key.indexOf('errorContact') > -1;
  }

  /**
   *
   * @param event KeyboardEvent the key pressed
   * @returns boolean true if the key pressed is a number DEL or backspace
   */
  validateInput(event: Event): boolean {
    if (event instanceof KeyboardEvent) {
      if (!!event.key?.match(/[0-9]|Backspace|Delete/)) {
        return true;
      }
    } else if (event.type === 'paste') {
      const clipboardData = (event as ClipboardEvent).clipboardData;
      const pastedText = clipboardData?.getData('text/plain').trim();

      if (pastedText?.match(/[0-9]/)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Get access to clipboard data from paste event and clean it to allow only numbers
   *
   * @param e ClipboardEvent
   */
  pasteNumbers(e: any): void {
    e.preventDefault();
    let clipboardData = e.clipboardData.getData('text/plain').trim();
    if (clipboardData) {
      e.target.value = clipboardData.replace(/[^0-9]/g, '');
    }

    this.form.setValue(e.target.value);
  }

  goInsertCode(): void {
    this.isLoading = false;
    this.configureScreen(VerificationScreen.INSERT_VERIFICATION_CODE);
  }

  goInitialScreen(): void {
    this.configureScreen(VerificationScreen.INITIAL_SCREEN);
  }

  requestNewCode(): void {
    this.isLoading = true;
    this.verificationDao.requestCode().subscribe({
      next: () => {
        this.submitted = false;
        this.isLoading = false;
        this.configureScreen(VerificationScreen.INSERT_VERIFICATION_CODE);
      },

      error: e => {
        this.submitted = false;
        this.isLoading = false;
        this.info = this.parseError(e.error);
        if (this.info.minutesForWaitNextSMS) {
          this.configureScreen(VerificationScreen.REQUEST_CODE_FAILED);
        } else {
          this.configureScreen(VerificationScreen.DEVICE_BLOCKED);
        }
      },
    });
  }

  cleanErrors(): void {
    this.inputError = false;
    this.submitted = false;
    this.wrongCode.next('');
    this.cdr.markForCheck();
  }

  /**
   *
   * @returns boolean true if user has more attempts
   */
  private shouldShowInitialScreen() {
    return !this.info.smsSentOnThisRequest && !this.info?.maxAttemptsReached;
  }

  /**
   *
   * Sets the timer when user is blocked for reaching attempts limit
   *
   * @param errorDuration number, the remainig time until the user can require a new code
   * @param text text error
   * @param showTimer boolean to decide to show the counter or not
   */
  private setErrorInterval(
    errorDuration: number,
    text: TranslatableText,
    showTimer = true,
  ): void {
    this.cdr.markForCheck();
    this.destroy();
    let timerFinished = false;
    timer(0, 1000)
      .pipe(
        take(errorDuration),
        map(elapsed => errorDuration - elapsed),
        tap(remaining => (timerFinished = remaining - 1 === 0)),
        takeUntil(this.destroySubject),
      )
      .subscribe({
        next: remaining => {
          if (showTimer) {
            this.errorTimer = this.formatErrorMessage(text, remaining);
            this.cdr.markForCheck();
          }
        },
        complete: () => {
          if (timerFinished) {
            this.configureScreen(VerificationScreen.INITIAL_SCREEN);
          }
          this.cleanErrors();
          this.cdr.markForCheck();
        },
      });
  }

  /**
   * Destroys the timer when completed
   */
  private destroy(): void {
    this.destroySubject.next();
  }

  /**
   * Method used to configure screen according to flow
   *
   * @param nextScreen The next screen to configure
   */
  private configureScreen(nextScreen: VerificationScreen) {
    this.screen = nextScreen;
    this.destroy();
    switch (nextScreen) {
      case VerificationScreen.REQUEST_CODE_FAILED:
        this.buildModalRequestCodeFailed();
        break;

      case VerificationScreen.DEVICE_BLOCKED:
        this.buildModalDeviceBlocked();
        break;

      case VerificationScreen.INITIAL_SCREEN:
        this.buildModalInitialScreen();
        break;

      case VerificationScreen.INITIAL_SCREEN_ON_ERROR:
        this.buildModalInitialScreenOnError();
        break;

      case VerificationScreen.CONFIRM_CODE_SUCCESS:
        this.buildModalCodeSuccess();
        break;

      case VerificationScreen.INSERT_VERIFICATION_CODE:
        this.buildModalInsertVerificationCode();
        break;

      default:
        break;
    }
    this.cdr.markForCheck();
  }

  private buildModalRequestCodeFailed(): void {
    this.confirmButtonAction = 'error';
    this.title = {key: 'userProfile.verifyPhone.dialogIdentityBrowser'};
    this.info.maxAttemptsReached = true;
    const errorTimer = {
      key: 'userProfile.verifyPhone.errorSubtitle',
      keyData: {time: ''},
    };
    this.setErrorInterval(
      this.calculateWaitSeconds(
        this.info.minutesForWaitNextSMS * 60 * 1000,
        this.info.dateSendLast,
      ),
      errorTimer,
      true,
    );
    this.showDescriptionText = true;
    this.descriptionText = {
      key: 'userProfile.verifyPhone.dialogDescriptionInfoBrowser',
    };
    this.sendButtonText = {key: 'userProfile.verifyPhone.successButom'};
    this.wrongCode.next({key: 'userProfile.verifyPhone.verificationCodeError'});
    this.showInformation = true;
    this.confirmButtonAction = 'cancel';
    this.buttonCallBack = () => this.buttonRequestCodeFailed();
  }

  private buttonRequestCodeFailed(): boolean {
    this.submitted = false;
    this.isLoading = false;
    this.configureScreen(VerificationScreen.INSERT_VERIFICATION_CODE);
    return true;
  }

  private buildModalDeviceBlocked(): void {
    this.confirmButtonAction = 'cancel';
    this.title = {key: 'userProfile.verifyPhone.errorTitleBrowser'};
    this.image = 'assets/img/seguridad/nav-pass-red.png';
    this.info.maxAttemptsReached = true;
    const errorTimer = {
      key: 'userProfile.verifyPhone.errorSubtitle',
      keyData: {time: ''},
    };
    this.setErrorInterval(environment.verification.maxTimeBlockedUser, errorTimer);
    this.showDescriptionText = true;
    this.showInformation = false;
    this.isLoading = false;
    this.descriptionText = {key: 'userProfile.verifyPhone.errorContact'};
    this.sendButtonText = {key: 'userProfile.verifyPhone.successButom'};
    this.wrongCode.next({key: 'userProfile.verifyPhone.verificationCodeError'});
    this.buttonCallBack = () => this.buttonCloseModal();
  }

  private buttonCloseModal(): boolean {
    this.closeDialog(this.confirmButtonAction);
    return true;
  }

  private buildModalInitialScreen(): void {
    this.showDescriptionText = true;
    this.descriptionText = {
      key: 'userProfile.verifyPhone.dialogDescriptionInfoBrowser',
    };
    this.submitted = false;
    this.sendButtonText = {key: 'userProfile.verifyPhone.sendCode'};
    this.buttonCallBack = () => this.buttonInitialScreen();
  }

  private buttonInitialScreen(): boolean {
    this.requestNewCode();
    return true;
  }

  private buildModalInitialScreenOnError(): void {
    this.confirmButtonAction = 'cancel';
    this.title = {key: 'userProfile.verifyPhone.errorTitleBrowser'};
    this.image = 'assets/img/seguridad/nav-pass-red.png';
    this.showDescriptionText = true;
    this.descriptionText = {key: 'userProfile.verifyPhone.errorContact'};
    this.sendButtonText = {key: 'userProfile.verifyPhone.successButom'};
    this.wrongCode.next({key: 'userProfile.verifyPhone.verificationCodeError'});
    this.buttonCallBack = () => this.buttonCloseModal();
    const errorTimer = {
      key: 'userProfile.verifyPhone.retryExpiredCodeDisabled',
      keyData: {message: ''},
    };
    this.showInformation = false;
    this.setErrorInterval(this.info.secondsToRetry, errorTimer);
  }

  private buildModalCodeSuccess(): void {
    this.measurementDao.sendTagManagerEvent('device_verification_complete');
    this.confirmButtonAction = 'success';
    this.title = {key: 'userProfile.verifyPhone.successTitleBrowser'};
    this.showDescriptionText = true;
    this.descriptionText = {key: 'userProfile.verifyPhone.successSubtitle'};
    this.sendButtonText = {key: 'userProfile.verifyPhone.successButom'};
    this.image = 'assets/img/seguridad/nav-pass-green.png';
    this.showInformation = false;
    this.buttonCallBack = () => this.buttonCloseModal();
  }

  private buildModalInsertVerificationCode(): void {
    this.measurementDao.sendTagManagerEvent('device_verification_start');
    this.sendButtonText = {key: 'userProfile.verifyPhone.verifyCode'};
    this.showDescriptionText = false;
    this.cleanErrors();
    this.title = {
      key: 'userProfile.verifyPhone.titleCode',
    };
    this.form.reset();
    this.buttonCallBack = () => this.confirmCode();
  }

  private parseError(err: any): VerificationInfo {
    let info: VerificationInfo = {};
    info.dateSendLast = err.dateSendLast;
    info.minutesForWaitNextSMS = err.minutesForWaitNextSMS;
    info.secondsToRetry = err.secondsToRetry;
    info.smsSentOnThisRequest = err.smsSentOnThisRequest;
    info.lastTimeSmsSent = err.lastTimeSmsSent;
    info.smsSentOnThisRequest = err.smsSentOnThisRequest;
    return info;
  }

  private formatErrorMessage(
    text: TranslatableText,
    remaining: number,
  ): TranslatableText {
    const translatableText = {...text};
    const dataKeys = Object.keys(translatableText.keyData);
    translatableText.keyData[dataKeys[0]] = formatMinute(remaining);
    return translatableText;
  }

  /**
   * Return wait seconds to next SMS from last SMS
   *
   * @param waitMilliseconds
   * @param dateSendLast
   * @private
   */
  private calculateWaitSeconds(
    waitMilliseconds: number,
    dateSendLast: number,
  ): number {
    const difference = Date.now() - dateSendLast;
    const remainingTime = differenceInSeconds(waitMilliseconds, difference);
    return remainingTime > 0 ? remainingTime : 0;
  }
}
