import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable, Subject, takeUntil} from 'rxjs';
import {Destroyable} from '../../util/destroyable';

@Component({
  selector: 'tl-input-password-validations',
  templateUrl: './input-password-validations.component.html',
  styleUrls: ['./input-password-validations.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPasswordValidationsComponent implements OnInit {
  @Input()
  form!: FormGroup;

  @Input()
  control!: FormControl;

  @Input()
  messagesFieldName = 'password';

  @Input()
  submitted = false;

  @Input()
  showPasswordPersonalInfoError?: Observable<void>;

  shouldShowPasswordPersonalInfoError = false;

  messages = {
    'password.uppercaseNeeded': 'authentication.userRegister.validations.upperCase',
    'password.lowercaseNeeded': 'authentication.userRegister.validations.lowerCase',
    'password.numberNeeded': 'authentication.userRegister.validations.number',
    'password.symbolNeeded': 'authentication.userRegister.validations.specialChar',
    'password.length': 'authentication.userRegister.validations.minChars',
  };

  personalInfoMsg =
    'authentication.userRegister.formRegister.infoValidations.personalInfoError';

  get showSuccess(): boolean {
    return this.control.valid;
  }

  @Destroyable()
  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.shouldShowPasswordPersonalInfoError = false;
      this.cdr.markForCheck();
    });
    this.showPasswordPersonalInfoError
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.shouldShowPasswordPersonalInfoError = true;
        this.cdr.markForCheck();
      });
  }

  sortNull() {
    return null;
  }
}
