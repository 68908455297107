import * as deepmerge from 'deepmerge';

import {ENVIRONMENT_GLOBAL} from './global';

export const ENVIRONMENT_DEV = deepmerge.all<any>([
  {},
  ENVIRONMENT_GLOBAL,
  {
    production: false,
    angularProduction: false,
    logger: {endpoint: 'http://elk1.tulotero.net/webapp'},
    localStorageKeys: {
      allInfo: 'allInfo',
      token: 'token',
      appVersion: 'appVersion',
      sponsorNew: 'sponsorNew',
      currentPlay: 'currentPlay',
      deviceId: 'deviceId',
      installation: 'installation',
      popupStates: 'popupStates',
      contacts: 'contacts',
      googleLogin: 'googleLogin',
      messageBanner: 'messageBanner',
      googleToken: 'googleToken',
      geolocationPermission: 'geolocation',
      fingerprint: 'fingerprint',
      freshNews: 'freshNews',
      kycLaunched: 'kycLaunched',
      kycPending: 'kycPending',
      counterShares: 'counterShares',
      boothsLastDate: 'boothsLastDate',
      lastAccess: 'lastAccess',
      cameraDevideIdBack: 'cameraDevideIdBack',
      cameraDevideIdFront: 'cameraDevideIdFront',
      persistentSavePlays: 'persistentSavePlays',
      selectedNotificationState: 'selectedNotificationState',
      ticketsGameFilters: 'ticketsGameFilters',
      winnersLastDate: 'winnersLastDate',
      winners: 'winners',
      reviewsLastDate: 'reviewsLastDate',
      reviews: 'reviews',
      ratingsLastDate: 'ratingsLastDate',
      ratings: 'ratings',
      winnersState: 'winnersState',
      pushPrompted: 'pushPrompted',
      pushLastReminder: 'pushLastReminder',
      userLocale: 'userLocale',
      media: 'media',
      mediaLastDate: 'mediaLastDate',
      mediaGame: 'mediaGame',
      mediaLocale: 'mediaLocale',
    },
    google: {
      clientId:
        '314470230639-svp46pun37qe8ks1mdb771okq0tqhnb9.apps.googleusercontent.com',
    },
    redirect: {
      mx: {url: 'http://web.mx.pre.tulotero.net/'},
      es: {url: 'http://web.es.pre.tulotero.net/'},
      us: {url: 'http://web.us.stage.tulotero.net/'},
      pt: {url: 'http://web.pt.stage.tulotero.net/'},
    },
    chatLimit: 100,
    useIndexedDB: false,
    noDeleteAllInfoInVersionURL:
      '/tulotero-static-proxy-global/versions-web-pre.json',
    phone: {
      skipValidation: [
        '700000000', // Bizum test number
      ],
    },
    money: {
      resizeVoucherImageMaxDimension: 600,
    },
  },
]);
