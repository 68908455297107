import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {DeviceService, LocaleService} from 'common';

@Component({
  selector: 'tl-geolocation-blocked-modal',
  templateUrl: './geolocation-blocked-modal.component.html',
  styleUrls: ['./geolocation-blocked-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeolocationBlockedModalComponent implements OnInit {
  @HostBinding('class.tl-geolocation-blocked-modal') readonly hostClass = true;

  stepsImage: string;
  browserWithoutSteps = false;

  constructor(
    private localeService: LocaleService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.setImage();
  }

  private setImage(): void {
    this.browserWithoutSteps = !(
      this.deviceService.isChrome ||
      this.deviceService.isFirefox ||
      this.deviceService.isSafari
    );

    if (!this.browserWithoutSteps) {
      const locale = this.localeService.getLocale().split('-')[0];
      let browser: string;
      if (this.deviceService.isChrome) {
        browser = 'chrome';
      } else if (this.deviceService.isFirefox) {
        browser = 'firefox';
      } else if (this.deviceService.isSafari) {
        browser = 'safari';
      }
      // eslint-disable-next-line max-len
      this.stepsImage = `/assets/img/geolocalizacion/blocked/geolocation_blocked_steps_${browser}_${locale}.png`;
    }
  }
}
