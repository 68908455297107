import {Inject, Injectable} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  ignoreError,
  ModalDialogComponent,
  ModalDialogLayout,
  ModalHelperService,
  ResponsiveService,
  ROUTES,
  TranslationsService,
} from 'common';
import {EMPTY, first, iif, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import {
  processGeolocationError,
  RejectedGeolocationError,
} from './geolocation-errors';
import {GeolocationService} from './geolocation.service';
import {UserService} from '../user/data/user.service';
import {GeolocationPermission} from './geolocation-permission-type';
import {GeolocationBlockedModalComponent} from './geolocation-blocked-modal/geolocation-blocked-modal.component';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class GeolocationViewService {
  permissionPregranted: GeolocationPermission;

  get permissionDenied(): boolean {
    return (
      this.geolocationService.getPermissionStatus() === 'dismissed' ||
      this.permissionPregranted === GeolocationPermission.DENIED
    );
  }

  private loadingModalRef: NgbModalRef;

  constructor(
    private geolocationService: GeolocationService,
    private modalHelperService: ModalHelperService,
    private translationsService: TranslationsService,
    private userService: UserService,
    private responsiveService: ResponsiveService,
    private router: Router,
    @Inject(ROUTES) private routes: Record<string, any>,
  ) {}

  getGeolocation(): Observable<void | GeolocationPosition> {
    return this.geolocationService.checkGeolocationPermissions().pipe(
      switchMap(hasPermissions =>
        iif(
          () => hasPermissions,
          this.geolocationService.getBestCurrentLocation().pipe(
            first(),
            switchMap(() => EMPTY),
          ),
          EMPTY,
        ),
      ),
    );
  }

  /**
   * Logic which defines how UI requests geolocation to user when need order booths.
   *
   */
  requestLocationToOrderBooths(options?): Observable<GeolocationPosition> {
    return this.requestLocation(
      {
        title: {key: 'geolocation.warningPermissions.title'},
        accept: {key: 'geolocation.warningPermissions.actions.accept'},
        message: null,
        ...options,
      },
      false,
    );
  }

  /**
   * Logic which defines how UI requests geolocation to user when is required to load money.
   *
   */
  requestLocationToLoad(): Observable<GeolocationPosition> {
    return this.userService.getData().pipe(
      first(),
      switchMap(user =>
        this.requestLocationFlow(
          {
            type: 'ok',
            title: {key: 'userProfile.balance.dialogLocationNeededToLoad.title'},
            message: [
              {
                key: 'userProfile.balance.dialogLocationNeededToLoad.description',
                keyData: {userName: user?.name},
              },
            ],
            accept: {
              key: 'userProfile.balance.dialogLocationNeededToLoad.acceptAction',
            },
            image: 'assets/img/geolocalizacion/gps.png',
          },
          {
            type: 'ok_cancel_only',
            title: {
              key: 'userProfile.balance.dialogLocationNeededToLoad.titleWebDismissed',
            },
            message: [
              {
                key: 'userProfile.balance.dialogLocationNeededToLoad.description',
                keyData: {userName: user?.name},
              },
              {
                key: 'userProfile.balance.dialogLocationNeededToLoad.descriptionWebDismissed',
              },
            ],
            accept: {
              key: 'userProfile.balance.dialogLocationNeededToLoad.seeHowAction',
            },
            acceptCallBack: () => {
              this.showGeolocationBlockedModal();
              return true;
            },
            cancel: {key: 'global.gotIt'},
            image: 'assets/img/geolocalizacion/gps-denied.png',
          },
        ),
      ),
    );
  }

  /**
   * Logic which defines how UI requests geolocation to user when is required to play.
   *
   */
  requestLocationToPlay(): Observable<GeolocationPosition> {
    return this.userService.getData().pipe(
      first(),
      switchMap(user =>
        this.requestLocationFlow(
          {
            type: 'ok',
            title: {key: 'games.play.dialogLocationNeeded.title'},
            message: [
              {
                key: 'games.play.dialogLocationNeeded.description',
                keyData: {userName: user?.name},
              },
            ],
            accept: {key: 'games.play.dialogLocationNeeded.acceptAction'},
            image: 'assets/img/geolocalizacion/gps.png',
          },
          {
            type: 'ok_cancel_only',
            title: {key: 'games.play.dialogLocationNeeded.titleWebDismissed'},
            message: [
              {
                key: 'games.play.dialogLocationNeeded.description',
                keyData: {userName: user?.name},
              },
              {
                key: 'games.play.dialogLocationNeeded.descriptionWebDismissed',
              },
            ],
            accept: {key: 'games.play.dialogLocationNeeded.seeHowAction'},
            acceptCallBack: () => {
              this.showGeolocationBlockedModal();
              return true;
            },
            cancel: {key: 'global.gotIt'},
            image: 'assets/img/geolocalizacion/gps-denied.png',
          },
        ),
      ),
    );
  }

  /**
   * Logic which defines how UI requests geolocation from user.
   *
   */
  requestLocation(options?, showLoading = true): Observable<GeolocationPosition> {
    return this.showGeolocationPrompt(
      this.permissionPregranted !== GeolocationPermission.GRANTED,
      options,
    ).pipe(
      tap(() => showLoading && this.showLoading()),
      switchMap(() => this.geolocationService.getBestCurrentLocation()),
      tap(() => showLoading && this.hiddenLoading()),
      // Handle errors and keep non located lottery booths.
      catchError(e => {
        if (showLoading) {
          this.hiddenLoading();
        }
        if (e instanceof RejectedGeolocationError) {
          return this.translateError(e).pipe(
            switchMap(err => throwError(() => err)),
          );
        }
        return this.translateError(processGeolocationError(e)).pipe(
          switchMap(err => throwError(() => err)),
        );
      }),
    );
  }

  showGeolocationPrompt(
    showModal = !this.permissionPregranted,
    options?,
  ): Observable<null | string> {
    const modalOptions = {
      type: 'ok_cancel_only',
      ...options,
    };
    if (
      (this.geolocationService.getPermissionStatus() === 'default' ||
        this.geolocationService.getPermissionStatus() === null) &&
      showModal
    ) {
      return this.openGeolocationPromptModal(modalOptions).pipe(
        // If prompt is cancelled, there is nothing to do.
        catchError(() => {
          this.permissionPregranted = GeolocationPermission.DENIED;
          return throwError(() => new RejectedGeolocationError());
        }),
      );
    }
    if (this.geolocationService.getPermissionStatus() === 'dismissed') {
      return this.showGeolocationBlockedModal();
    }
    return of(null);
  }

  /**
   * Logic which defines how UI requests geolocation from user when full flow is required (US).
   *
   */
  requestLocationFlow(options?, optionsDenied?): Observable<GeolocationPosition> {
    const showModal =
      this.permissionPregranted !== GeolocationPermission.GRANTED &&
      this.geolocationService.getPermissionStatus() !== 'granted';

    const initialPermissionsDenied = this.permissionDenied;

    return (
      !showModal
        ? of(null)
        : this.showFlowGeolocationPrompt(
            this.permissionDenied ? optionsDenied : options,
          )
    ).pipe(
      switchMap(() => {
        this.showLoading();

        return this.geolocationService.getBestCurrentLocation().pipe(
          tap(() => this.hiddenLoading()),
          catchError(err => {
            this.hiddenLoading();

            // show denied prompt if permission was just denied
            if (this.permissionDenied && !initialPermissionsDenied) {
              this.showFlowGeolocationPrompt(optionsDenied);
              return throwError(null);
            }

            return throwError(() => err);
          }),
        );
      }),
    );
  }

  showFlowGeolocationPrompt(options): Observable<null | string> {
    // this dialog is when dont have access to location.
    return this.openGeolocationPromptModal(options);
  }

  /**
   * Ask the user for geolocation permission if not already given and returns
   * the location or an error if the native permission is not granted.
   */
  requireGeolocation(options?): Observable<GeolocationPosition> {
    return this.requestLocation(options).pipe(ignoreError(RejectedGeolocationError));
  }

  /**
   * Show modal "We were unable to determine your location"
   */
  showModalUndeterminatedLocation(): Observable<any> {
    return this.modalHelperService.openOkModal(ModalDialogComponent, {
      modalOptions: {centered: true},
      componentParams: {
        type: 'ok',
        title: {key: 'availableStatesDialog.titleNoDetermineLocation'},
        message: {key: 'availableStatesDialog.contentNoDetermineLocation'},
        accept: {key: 'availableStatesDialog.gotItButton'},
      },
    });
  }

  /**
   * Return the translation of a Error
   *
   * @param error
   * @private
   */
  private translateError(error: Error): Observable<Error> {
    return this.translationsService.getTranslation(error.message).pipe(
      first(),
      map(translation => new Error(translation)),
    );
  }

  private showLoading(): void {
    this.loadingModalRef = this.modalHelperService.openModal(ModalDialogComponent, {
      modalOptions: {centered: true},
      componentParams: {
        type: 'ok_only',
        loading: true,
        title: {key: 'check.toast.obtainingLocation'},
        buttonsPadded: true,
      },
    });
  }

  private hiddenLoading(): void {
    this.loadingModalRef.close();
  }

  private openGeolocationPromptModal(options?) {
    const defaultModalOptions = {
      type: 'ok',
      title: {key: 'games.play.dialogLocationNeeded.title'},
      message: {
        key: 'games.play.dialogLocationNeeded.descriptionWeb',
      },
      accept: {key: 'games.play.dialogLocationNeeded.acceptAction'},
      image: 'assets/img/geolocalizacion/gps.png',
      imageWidth: 100,
      layout: ModalDialogLayout.VERTICAL,
    };
    const modalOptions = {
      ...defaultModalOptions,
      ...options,
    };
    return this.modalHelperService.openOkCancelModal(ModalDialogComponent, {
      componentParams: modalOptions,
      modalOptions: {
        modalDialogClass: 'tl-geolocation-prompt tl-dlg-wrapper-column',
        centered: true,
      },
    });
  }

  private showGeolocationBlockedModal(): Observable<any> {
    if (this.responsiveService.isDesktop()) {
      return this.modalHelperService
        .openOkModal(GeolocationBlockedModalComponent, {
          modalOptions: {centered: true},
        })
        .pipe(switchMap(() => EMPTY));
    } else {
      const route = this.routes.mobile.geolocationBlocked;
      this.router.navigate(['/m', route]);
      return EMPTY;
    }
  }
}
