import {Injectable, Injector, Optional} from '@angular/core';
import {
  AbstractObservableDataService,
  CountryService,
  LocalStorage,
  Logger,
  MessageHookAsapService,
  MessageHookDataService,
} from 'common';
import {Observable, Observer, of, Subscription} from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {BannerDataService} from '../../banner/data/banner-data.service';
import {LotteryBoothService} from '../../booth/data/lottery-booth.service';
import {TuLoteroLocalStorageService} from '../../common/local-storage/tu-lotero-local-storage.service';
import {ContactsService} from '../../contacts/data/contacts.service';
import {ErrorService} from '../../error/error.service';
import {ExclusionService} from '../../exclusion/model/exclusion.service';
import {GameFiltersService} from '../../games/game-filter/data/game/game-filters.service';
import {ResultFiltersService} from '../../games/game-filter/data/result/result-filters.service';
import {TicketFiltersService} from '../../games/game-filter/data/ticket/ticket-filters.service';
import {GameMetadataService} from '../../games/game-metadata/data/game-metadata.service';
import {GroupInfoService} from '../../games/groups/data/group-info.service';
import {GroupsService} from '../../games/groups/data/groups.service';
import {ResultsService} from '../../games/results/data/results.service';
import {SentencesService} from '../../games/sentences/sentences.service';
import {TicketsService} from '../../games/tickets/data/tickets.service';
import {UpcomingGamesService} from '../../games/upcoming/data/upcoming-games.service';
import {FailedWithdrawalsService} from '../../money/data/failed-withdrawals.service';
import {MoneyActivityUsersService} from '../../money/data/money-activity-users.service';
import {PendingWithdrawalsService} from '../../money/data/pending-withdrawals.service';
import {NewsService} from '../../tulotero/news/data/news.service';
import {UnfinishedPlayService} from '../../unfinished/model/unfinished-play.service';
import {UserService} from '../../user/data/user.service';
import {BackendDao} from '../backend.dao';
import {EndpointService} from '../endpoint/endpoint.service';

import {PrivateInfo} from './private-info';
import {StatesService} from '../../states/data/states.service';
import {TicketsCanceledService} from '../../games/tickets/data/tickets-canceled.service';
import {SubscriptionsNotExecutedService} from '../../games/tickets/data/subscriptions-not-executed.service';
import {TicketGameUserFiltersDataService} from '../../games/tickets/data/ticket-game-user-filters-data.service';
import {TicketGameUserFiltersService} from '../../games/tickets/data/ticket-game-user-filters.service';
import {Filter} from '../../games/game-filter/data/filter';
import {PreferenceDataService} from '../../preferences/data/preference-data.service';
import {GenericTicket} from '../../games/tickets/data/generic-ticket';
import {BoothsUriMapperService} from '../../booth/data/booths-uri-mapper';
import {PromosCardService} from '../../games/upcoming/data/promo-cards.service';
import {SessionService} from '../../user/auth/session.service';

@Injectable({providedIn: 'root'})
export class PrivateInfoService extends AbstractObservableDataService<PrivateInfo> {
  lastAllInfoCall = 0;

  protected autoStoreSubscription: Subscription;

  constructor(
    private backendDao: BackendDao,
    private endpointService: EndpointService,
    private errorsService: ErrorService,
    private exclusionService: ExclusionService,
    private failedWithdrawalsService: FailedWithdrawalsService,
    private gameFiltersService: GameFiltersService,
    private gameMetadataService: GameMetadataService,
    private groupInfoService: GroupInfoService,
    private groupsService: GroupsService,
    private injector: Injector,
    private tuLoteroLocalStorageService: TuLoteroLocalStorageService,
    private localStorage: LocalStorage,
    private logger: Logger,
    private lotteryBoothService: LotteryBoothService,
    private bannerDataService: BannerDataService,
    private moneyActivityService: MoneyActivityUsersService,
    private messageHookDataService: MessageHookDataService,
    private mesageHookAsapService: MessageHookAsapService,
    private newsService: NewsService,
    private pendingWithdrawalsService: PendingWithdrawalsService,
    private preferenceDataService: PreferenceDataService,
    private resultFiltersService: ResultFiltersService,
    private resultsService: ResultsService,
    private sentencesService: SentencesService,
    private subscriptionsNotExecutedService: SubscriptionsNotExecutedService,
    private statesService: StatesService,
    private ticketFiltersService: TicketFiltersService,
    private ticketsService: TicketsService,
    private ticketsCanceledService: TicketsCanceledService,
    private unfinishedPlayService: UnfinishedPlayService,
    private upcomingGamesService: UpcomingGamesService,
    private userService: UserService,
    private sessionService: SessionService,
    private countryService: CountryService,
    private boothsUriMapperService: BoothsUriMapperService,
    private promoCardsService: PromosCardService,
    // Only if `environment.features.tickets.filterGames` is enabled
    @Optional()
    private ticketGameUserFiltersDataService: TicketGameUserFiltersDataService,
    private ticketGameUserFiltersService: TicketGameUserFiltersService,
  ) {
    super();
  }

  load(): Observable<PrivateInfo> {
    return this.backendDao.allInfo().pipe(
      catchError(e => {
        this.sessionService.forceLogout = true;
        this.cleanPrivateServices();
        throw e;
      }),
      tap((privateInfo: PrivateInfo) => {
        this.setData(privateInfo);
        this.fillServices(privateInfo, privateInfo);
        this.lastAllInfoCall = new Date().getTime();
      }),
    );
  }

  restore(update: boolean): Observable<any> {
    return this.tuLoteroLocalStorageService
      .getItem(environment.localStorageKeys.allInfo)
      .pipe(
        switchMap(result => {
          let privateInfo = JSON.parse(result, PrivateInfo.reviver()) as PrivateInfo;
          if (privateInfo) {
            this.setData(privateInfo);
            this.fillServices(privateInfo, privateInfo);
            if (update) {
              // Launch update and not stop boot app
              this.update().pipe(first()).subscribe();
              return this.getData().pipe(first());
            }
            return this.getData().pipe(first());
          } else {
            return this.load();
          }
        }),
      );
  }

  update(): Observable<PrivateInfo> {
    return new Observable((observer: Observer<PrivateInfo>) => {
      this._data.pipe(first()).subscribe((privateInfo: PrivateInfo) => {
        this.backendDao.allInfo(privateInfo.lastUpdate).subscribe({
          next: (privateInfoDiff: PrivateInfo) => {
            this.lastAllInfoCall = new Date().getTime();
            if (privateInfoDiff) {
              try {
                privateInfo.merge(privateInfoDiff);
                privateInfo.reduce();
                this.fillServices(privateInfo, privateInfoDiff);
                this.setData(privateInfo);
                observer.next(privateInfo);
                observer.complete();
              } catch (e) {
                this.logger.error(e.message, e.stack);
                this.load().subscribe((p: PrivateInfo) => {
                  observer.next(p);
                  observer.complete();
                });
              }
            } else {
              observer.next(privateInfo);
              observer.complete();
            }
          },
          error: e => {
            this.errorsService.processErrorGlobalContext(e, {
              key: 'global.errorUpdatingUserInfo',
            });
            observer.error(e);
            observer.complete();
          },
        });
      });
    });
  }

  unload(): void {
    this.lastAllInfoCall = 0;
    this.tuLoteroLocalStorageService.removeItem(
      environment.localStorageKeys.allInfo,
    );
    this.cleanPrivateServices();
  }

  enableAutoStore(): void {
    this.autoStoreSubscription = this._data.subscribe(
      (newPrivateInfo: PrivateInfo) => {
        this.tuLoteroLocalStorageService
          .setItem(
            environment.localStorageKeys.allInfo,
            JSON.stringify(newPrivateInfo),
          )
          .subscribe();
      },
    );
  }

  disableAutoStore(): void {
    if (this.autoStoreSubscription) {
      this.autoStoreSubscription.unsubscribe();
      this.autoStoreSubscription = undefined;
    }
  }

  private fillServices(
    privateInfo: PrivateInfo,
    privateInfoDiff: PrivateInfo,
  ): void {
    if (privateInfoDiff.unfinishedPlays) {
      if (privateInfoDiff.unfinishedPlays.length > 0) {
        this.unfinishedPlayService.setUnfinishedPlays(
          privateInfoDiff.unfinishedPlays,
        );
      } else {
        this.unfinishedPlayService.clear();
      }
    }

    if (privateInfoDiff.freshNews) {
      this.newsService.setFreshNews(+privateInfoDiff.freshNews);
    }

    if (privateInfoDiff.gameMetadata && privateInfoDiff.gameMetadata.length > 0) {
      this.gameMetadataService.setMetadata(privateInfo.gameMetadata);
    }

    if (
      privateInfoDiff.endpointInfo.allowBooths &&
      privateInfoDiff.lotteryBooths &&
      privateInfoDiff.lotteryBooths.length > 0
    ) {
      this.lotteryBoothService.setData(privateInfo.lotteryBooths);
    }

    if (privateInfoDiff.moneyActivities) {
      this.moneyActivityService.setData(privateInfo.moneyActivities);
    }

    if (privateInfoDiff.moneyActivitiesMaxPagination) {
      this.moneyActivityService.setMaxPagination(
        privateInfoDiff.moneyActivitiesMaxPagination,
      );
    }

    if (privateInfoDiff.preferences && privateInfoDiff.preferences.length > 0) {
      this.preferenceDataService.setData(privateInfo.preferences);
    }

    if (privateInfoDiff.results && privateInfoDiff.results.length > 0) {
      if (this.resultsService.filter) {
        this.resultsService.reset().subscribe();
      } else {
        this.resultsService.setData(privateInfo.results);
      }
    }

    if (privateInfoDiff.sentences && privateInfoDiff.sentences.length > 0) {
      this.sentencesService.setData(privateInfo.sentences);
    }

    // privateInfo === privateInfoDiff means: AllInfo complete should set an
    // empty array to avoid waiting ticketsService.getData()
    if (
      privateInfo === privateInfoDiff ||
      (privateInfoDiff.tickets && privateInfoDiff.tickets.length > 0) ||
      (privateInfoDiff.ticketsDeleted && privateInfoDiff.ticketsDeleted.length > 0)
    ) {
      this.ticketsService.setData(privateInfo.tickets, privateInfoDiff.totalTickets);
    }

    if (
      environment.geolocation.enableAppState &&
      environment.features.tickets.filterGames
    ) {
      this.updateFiltersIfNeeded(privateInfo, privateInfoDiff);
    }

    if (privateInfoDiff.contacts && privateInfoDiff.contacts.length > 0) {
      let contactsService = this.injector.get(ContactsService);
      contactsService.setData(privateInfo.contacts);
    }

    if (privateInfoDiff.upcomingGames && privateInfoDiff.upcomingGames.length > 0) {
      this.upcomingGamesService.setData(privateInfo.upcomingGames);
    }

    if (privateInfoDiff.promoCards) {
      this.promoCardsService.setData(privateInfo.promoCards);
    }

    if (privateInfoDiff.user) {
      this.userService.setData(privateInfoDiff.user);
    }

    if (privateInfoDiff.countries) {
      this.countryService.setData(privateInfo.countries);
    }

    if (privateInfoDiff.states) {
      this.statesService.setData(privateInfo.states);
    }

    if (privateInfoDiff.endpointInfo) {
      this.endpointService.setData(privateInfoDiff.endpointInfo);
      this.resultFiltersService.setFiltersFromBackend(
        privateInfo.endpointInfo.resultFilters,
        privateInfo.endpointInfo.fixedResultFilters,
      );
      if (
        !(
          environment.geolocation.enableAppState &&
          environment.features.tickets.filterGames
        )
      ) {
        this.ticketFiltersService.setFiltersFromBackend(
          privateInfo.endpointInfo.ticketFilters,
          privateInfo.endpointInfo.fixedTicketFilters,
        );
      }

      if (!!privateInfo.endpointInfo.gameFilters) {
        this.gameFiltersService.setFiltersFromBackend(
          privateInfo.endpointInfo.gameFilters,
          privateInfo.endpointInfo.fixedGameFilters,
        );
      }

      this.messageHookDataService.setData(privateInfo.endpointInfo.messageHooks);

      if (privateInfoDiff.endpointInfo.retailersEndpoint) {
        this.boothsUriMapperService.setData(
          privateInfoDiff.endpointInfo.retailersEndpoint.reduce(
            (acc, next) => acc.set(next.region, next.url),
            new Map(),
          ),
        );
      }
    }

    this.mesageHookAsapService.setData(privateInfo.messageHooks);
    this.bannerDataService.setData(
      [
        privateInfo.messageBanner,
        ...(privateInfo?.messagesBannerByState ?? []),
      ].filter(x => !!x),
    );

    if (privateInfoDiff.groups) {
      this.groupsService.setData(privateInfo.groups);
      if (this.groupInfoService.listenGroupChanges.getValue()) {
        this.groupInfoService
          .getData()
          .pipe(
            first(),
            filter(group => !!group),
            map(group => group.id),
            filter(groupId =>
              privateInfoDiff.groups.some(group => group.id === groupId),
            ),
            takeUntil(
              this.groupInfoService.getData().pipe(filter(listen => !listen)),
            ),
            switchMap(() => this.groupInfoService.update()),
          )
          .subscribe();
      }
    }

    if (privateInfoDiff.pendingWithdrawals) {
      this.pendingWithdrawalsService.setData(privateInfo.pendingWithdrawals);
    }

    if (privateInfoDiff.failedWithdrawals) {
      this.failedWithdrawalsService.setData(privateInfo.failedWithdrawals);
    }

    if (privateInfoDiff.selfControl) {
      this.exclusionService.setShowMenu(privateInfoDiff.selfControl.showMenu);
    }

    this.ticketsCanceledService.setData(privateInfoDiff.ticketsCanceled ?? []);
    privateInfo.ticketsCanceled = [];

    this.subscriptionsNotExecutedService.setData(
      privateInfoDiff.subscriptionsNotExecuted,
    );
    privateInfo.subscriptionsNotExecuted = null;
  }

  private updateFiltersIfNeeded(
    privateInfo: PrivateInfo,
    privateInfoDiff: PrivateInfo,
  ): void {
    // Sometimes backend not send the accountStateCode on user extras
    const accountStateCodeChanged =
      privateInfo.user?.accountStateCode !== privateInfoDiff.user?.accountStateCode;
    // When user buy new tickets we need check if filters should be updated
    // We must update filters when gameId is not included in ticket user filters.
    const ticketsChanged = privateInfoDiff.tickets.length > 0;
    const ticketsGamesIds = privateInfoDiff.tickets
      .map((t: GenericTicket) => t.bet.gameId)
      .filterUnique((gameId: string) => gameId);

    const filters$ = accountStateCodeChanged
      ? this.ticketGameUserFiltersService.getFilters().pipe(first())
      : this.getExistingOrNewFilters(ticketsChanged, ticketsGamesIds);

    filters$.subscribe(filters => {
      this.ticketGameUserFiltersDataService.setData(filters);
      this.ticketFiltersService.setFiltersFromBackend(
        filters,
        privateInfo.endpointInfo.fixedTicketFilters,
      );
    });
  }

  private getExistingOrNewFilters(
    ticketsChanged: boolean,
    ticketsGamesIds: Array<string>,
  ): Observable<Array<Filter>> {
    return this.ticketGameUserFiltersDataService.getData().pipe(
      first(),
      switchMap(filters => {
        if (
          !filters ||
          filters.length === 0 ||
          (ticketsChanged &&
            ticketsGamesIds.some(
              gameId => !filters.flatMap((f: Filter) => f.gameIds).includes(gameId),
            ))
        ) {
          return this.ticketGameUserFiltersService.getFilters().pipe(first());
        }
        return of(filters);
      }),
    );
  }

  private cleanPrivateServices(): void {
    this.ticketsService.setData([]);
    this.moneyActivityService.setData([]);
    this.groupsService.setData([]);
    this.userService.setData(null);

    this.unfinishedPlayService.clear();

    let contacts = this.localStorage.getItem(environment.localStorageKeys.contacts);
    if (contacts && !!contacts.length) {
      let contactsService = this.injector.get(ContactsService);
      contactsService.clearData();
    }
    this.localStorage.removeItem(
      environment.localStorageKeys.googleContactsPermission,
    );
    this.pendingWithdrawalsService.setData([]);
    this.failedWithdrawalsService.setData([]);
    this.ticketsCanceledService.setData([]);
    if (environment.features.tickets.filterGames) {
      this.ticketGameUserFiltersDataService.setData([]);
    }
    this.subscriptionsNotExecutedService.setData(null);
    this.localStorage.removeItem(environment.localStorageKeys.persistentSavePlays);
    this.localStorage.removeItem(environment.localStorageKeys.userLocale);
  }
}
