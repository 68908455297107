import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {transition, trigger, useAnimation} from '@angular/animations';
import {TranslatableText} from '../i18n/translatable-text';
import {slideInVertical, slideOutVertical} from '../animation/angular/slide';

@Component({
  selector: 'tl-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('SlideFromBottom', [
      transition(
        ':enter',
        useAnimation(slideInVertical, {
          params: {start: '100%', timing: '.3s .2s ease-in'},
        }),
      ),
      transition(
        ':leave',
        useAnimation(slideOutVertical, {
          params: {end: '100%', timing: '.3s .2s ease-in'},
        }),
      ),
    ]),
  ],
})
export class ToastComponent {
  @HostBinding('class.tl-toast')
  readonly hostClass = true;

  @HostBinding('@SlideFromBottom')
  readonly animate = true;

  @Input()
  @HostBinding('class.tl-toast--static')
  static = false;

  @Input()
  @HostBinding('class.bottom-closer')
  bottomCloser = false;

  @Input()
  @HostBinding('class.lower-line-height')
  lowerLineHeight = false;

  @Input()
  message: string | TranslatableText;
}
