import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

import {IframeEvent} from './iframe-event';
import {URI_MAP_PREFIX} from '../uri/uri-mapper.token';

declare type IFrameListeners =
  | {
      body: string;
    }
  | {window: string}
  | {class: string}
  | {internalUrl: string}
  | {string: any};

@Directive({selector: 'iframe[tlIframeEventListener]'})
export class IframeEventListenerDirective implements OnDestroy {
  @Output('listenerChange')
  customEventChange = new EventEmitter<IframeEvent>();

  listeners: IFrameListeners;

  private subscriptions: Array<Subscription> = [];

  private iframeLoaded = false;

  constructor(
    private element: ElementRef,
    @Inject(URI_MAP_PREFIX) private prefix: string,
  ) {}

  ngOnDestroy() {
    this.drainSubscriptions();
  }

  @Input('tlIframeEventListener')
  set setListeners(values: IFrameListeners) {
    this.listeners = values;
    this.observeEvents();
  }

  @HostListener('load')
  load() {
    this.iframeLoaded = true;
    this.observeEvents();
  }

  private observeEvents() {
    let frameDocument =
      this.element.nativeElement.contentDocument ||
      this.element.nativeElement.contentWindow.document;
    this.drainSubscriptions();
    if (this.iframeLoaded && this.listeners) {
      Object.keys(this.listeners)
        .filter(key => this.listeners.hasOwnProperty(key))
        .forEach(key => {
          let element: HTMLElement;
          if (key === 'window') {
            element = this.element.nativeElement.contentWindow;
          } else if (key === 'document') {
            element = frameDocument;
          } else if (key === 'class') {
            element = frameDocument.querySelector('.'.concat(this.listeners[key]));
          } else if (key === 'internalUrl') {
            // Recover internal urls. All internal urls should start with tulotero
            const internalAnchors: Array<HTMLElement> = Array.from(
              frameDocument.querySelectorAll('a[href^="' + this.prefix + '"]'),
            );
            if (internalAnchors && internalAnchors.length > 0) {
              internalAnchors.forEach(anchor =>
                this.subscribeTo(
                  anchor,
                  'click',
                  new IframeEvent(this.listeners[key], anchor.getAttribute('href')),
                ),
              );
            }
          } else {
            element = frameDocument.querySelector(
              `[data-${key.replace(/([A-Z])/g, '-$1').toLowerCase()}]`,
            );
          }
          if (element) {
            this.subscribeTo(
              element,
              'click',
              new IframeEvent(
                this.listeners[key],
                element.dataset ? element.dataset[key] : null,
              ),
            );
          }
        });
    }
  }

  private drainSubscriptions() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  private subscribeTo(
    element: HTMLElement,
    event: string,
    outputEvent: IframeEvent,
  ) {
    this.subscriptions.push(
      fromEvent(element, event).subscribe((e: MouseEvent) => {
        outputEvent.event = e;
        this.customEventChange.emit(outputEvent);
        e.stopPropagation();
      }),
    );
  }
}
