export class SpendLimits {
  // Limite maximo de gasto diario del usuario (No puede ser superior al spendingMaxLimitDailyByLaw)
  spendingLimitDaily: number;
  // Limite maximo de gasto semanal del usuario (No puede ser superior al spendingMaxLimitWeekly)
  spendingLimitWeekly: number;
  // Limite maximo carga de saldo diario del usuario
  // (No puede ser superior al balanceLoadMaxLimitDailyByLaw)
  balanceLoadLimitDaily: number;
  // Limite maximo carga de saldo semanal del usuario
  // (No puede ser superior al balanceLoadMaxLimitWeeklyByLaw)
  balanceLoadLimitWeekly: number;
  // Limite maximo carga de saldo mensual del usuario
  // (No puede ser superior al balanceLoadMaxLimitMonthlyByLaw)
  balanceLoadLimitMonthly: number;
  // Limite maximo de carga de saldo diario del estado del usuario
  balanceLoadMaxLimitDaily: number;
  // Limite maximo de carga de saldo semanal del estado del usuario
  balanceLoadMaxLimitWeekly: number;
  // Limite maximo de carga de saldo mensual del estado del usuario
  balanceLoadMaxLimitMonthly: number;
  // Limite maximo de gasto diario del estado del usuario
  spendingMaxLimitDaily: number;
  // Limite maximo de gasto diario del estado del usuario
  spendingMaxLimitWeekly: number;
  // Limite maximo de carga de saldo diario por ley del estado del usuario
  balanceLoadMaxLimitDailyByLaw: number;
  // Limite maximo de carga de saldo semanal por ley del estado del usuario
  balanceLoadMaxLimitWeeklyByLaw: number;
  // Limite maximo de carga de saldo mensual por ley del estado del usuario
  balanceLoadMaxLimitMonthlyByLaw: number;
  // Limite maximo de gasto diario por ley del estado del usuario
  spendingMaxLimitDailyByLaw: number;
  // Property de las horas de espera que hay que esperar tras hacer un incremento
  // de los limites del usuario (Se pasa el valor de la property,
  // no el tiempo restante antes de poder modificar)
  waitingHoursBeforeIncreasingLimits: number;
  // Fecha ultima modificacion limites del usuario
  lastLimitsModifiedDate: number;
  // Se usa para España, para pasar un test de control si se quieren aumentar limites
  hasDefaultMaxLimits: boolean;

  static createFromBackend(obj: any): SpendLimits {
    let limits: SpendLimits = new SpendLimits();
    limits.spendingLimitDaily = SpendLimits.calculateEffectiveValue(
      obj.hasDefaultMaxLimits,
      obj.spendingLimitDaily,
      obj.spendingMaxLimitDaily,
      obj.spendingMaxLimitDailyByLaw,
    );
    limits.spendingMaxLimitDaily = obj.spendingMaxLimitDaily;
    limits.spendingMaxLimitDailyByLaw = obj.spendingMaxLimitDailyByLaw;
    limits.spendingLimitWeekly = obj.spendingLimitWeekly;
    limits.balanceLoadLimitDaily = SpendLimits.calculateEffectiveValue(
      obj.hasDefaultMaxLimits,
      obj.balanceLoadLimitDaily,
      obj.balanceLoadMaxLimitDaily,
      obj.balanceLoadMaxLimitDailyByLaw,
    );
    limits.balanceLoadMaxLimitDaily = obj.balanceLoadMaxLimitDaily;
    limits.balanceLoadMaxLimitDailyByLaw = obj.balanceLoadMaxLimitDailyByLaw;
    limits.balanceLoadLimitWeekly = SpendLimits.calculateEffectiveValue(
      obj.hasDefaultMaxLimits,
      obj.balanceLoadLimitWeekly,
      obj.balanceLoadMaxLimitWeekly,
      obj.balanceLoadMaxLimitWeeklyByLaw,
    );
    limits.balanceLoadMaxLimitWeekly = obj.balanceLoadMaxLimitWeekly;
    limits.balanceLoadMaxLimitWeeklyByLaw = obj.balanceLoadMaxLimitWeeklyByLaw;
    limits.balanceLoadLimitMonthly = SpendLimits.calculateEffectiveValue(
      obj.hasDefaultMaxLimits,
      obj.balanceLoadLimitMonthly,
      obj.balanceLoadMaxLimitMonthly,
      obj.balanceLoadMaxLimitMonthlyByLaw,
    );
    limits.balanceLoadMaxLimitMonthly = obj.balanceLoadMaxLimitMonthly;
    limits.spendingMaxLimitWeekly = obj.spendingMaxLimitWeekly;
    limits.balanceLoadMaxLimitMonthlyByLaw = obj.balanceLoadMaxLimitMonthlyByLaw;
    limits.waitingHoursBeforeIncreasingLimits =
      obj.waitingHoursBeforeIncreasingLimits;
    limits.lastLimitsModifiedDate = obj.lastLimitsModifiedDate;
    limits.hasDefaultMaxLimits = obj.hasDefaultMaxLimits;
    return limits;
  }

  // Si el usuario ha establecido un limite en un estado y en el nuevo estado
  // este es mayor que el maximo permitido se establecera el maximo permitido.
  private static calculateEffectiveValue(
    hasDefaultMaxLimits: boolean,
    limit: number | undefined,
    maxLimit: number,
    maxLimitByLaw: number | undefined,
  ): number {
    if (hasDefaultMaxLimits && !limit) {
      return maxLimit;
    }
    if (!limit || !maxLimitByLaw) {
      return Math.max(limit ?? 0, maxLimitByLaw ?? 0);
    }
    return Math.min(limit, maxLimitByLaw);
  }
}
