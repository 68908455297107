import {Serializable} from 'common';
import {environment} from '~environments/environment';

class TicketOptionsInternal {
  cancellable: boolean;
  deletable: boolean;
  deletableAll: boolean;
  extendable: boolean;
  prizeReturnable: boolean;
  sharable: boolean;
  repeatable: boolean;
  subscribable: boolean;
  /**Enables big prizes withdraw if they aren't from groups
   * and they've been approved in the panel  */
  proofBigPrize: boolean;
  showProof: boolean;
  downloadProof: boolean;
  archivable: boolean;
  showBigPrizeInstructions: boolean;
}

export class TicketOptions extends Serializable(TicketOptionsInternal) {
  public static createFromBackend(obj: any) {
    let to = new TicketOptions();

    to.cancellable = obj.cancellable;
    to.deletable = obj.deletable;
    to.deletableAll = obj.deletableAll;
    to.extendable = obj.extendable;
    to.sharable = obj.shareable;
    to.repeatable = obj.repetible;
    to.subscribable = obj.abonable;
    to.proofBigPrize = obj.constancia;
    to.prizeReturnable = obj.returnPrize;
    to.showProof = environment.features.tickets?.showProof;
    to.downloadProof = environment.features.tickets?.downloadProof;
    to.archivable = obj.archivable;
    to.showBigPrizeInstructions = obj.showBigPrizeInstructions;

    return to;
  }
}
