import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

import {Logger} from '../../logger/logger';
import {GOOGLE_MAPS_DEFAULT_ZOOM_IN} from '../config-tokens';
import {GoogleMapsService} from '../google-maps.service';

import {CustomMarkerType} from './custom-marker-type';
import {GeocodedPoint} from './geocoded-point';
import {CustomMarker, initCustomMarker} from './google-map-icon-overlay';
import {Subject} from 'rxjs';

@Component({
  selector: 'tl-google-map',
  template: ` <ng-content></ng-content>`,
  styleUrls: ['./google-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapComponent implements AfterViewInit {
  @HostBinding('class.google-map')
  readonly hostClass = true;

  @Input()
  initMapArgs: google.maps.MapOptions;

  @Output()
  init = new EventEmitter<void>();

  @Input()
  watchCenter = false;

  @Input()
  skipCenterOnSelection = false;

  @Input()
  fitBounds = false;

  @Output()
  markerSelected = new EventEmitter<string>();

  @Output()
  addressChange = new EventEmitter<GeocodedPoint>();

  @Output()
  dragStart = new EventEmitter<void>();

  @Output()
  dragEnd = new EventEmitter<google.maps.LatLng>();

  @Output()
  zoomChange = new EventEmitter<number>();

  @Output()
  tilesLoaded = new EventEmitter<boolean>();

  markers = new Array<CustomMarkerType>();

  selfMarker: CustomMarkerType;

  private map: google.maps.Map;

  private geoCoder: google.maps.Geocoder;

  private deleteMarkers: Subject<void> = new Subject<void>();
  private lastMarkerIdSelected: string;

  constructor(
    private elementRef: ElementRef,
    private googleMapsService: GoogleMapsService,
    private zone: NgZone,
    private logger: Logger,
    @Inject(GOOGLE_MAPS_DEFAULT_ZOOM_IN) private mapZoomIn: number,
  ) {}

  ngAfterViewInit(): void {
    this.googleMapsService.loadApiScript().subscribe(() => this.initMap());
  }

  /**
   * This is executed out of zone.
   * Not really sure why, but running it inside zone it doesn't work fine.
   * It initializes the map.
   */
  initMap(): void {
    this.map = new google.maps.Map(this.elementRef.nativeElement, this.initMapArgs);
    initCustomMarker();
    this.init.emit();
    this.map.addListener('tilesloaded', () => this.tilesLoaded.next(true));
    this.map.addListener('dragstart', () => this.dragStart.next());
    this.map.addListener('dragend', () => this.dragEnd.next(this.map.getCenter()));
    this.map.addListener('zoom_changed', () =>
      this.zoomChange.next(this.map.getZoom()),
    );

    this.geoCoder = new google.maps.Geocoder();

    if (this.fitBounds) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
        bounds.extend(marker.getPosition());
      });

      setTimeout(() => {
        this.map.fitBounds(bounds, 0);
      }, 500);
    }

    if (this.watchCenter) {
      this.zone.runOutsideAngular(() =>
        this.dragEnd
          .pipe(
            debounceTime(500),
            filter(() => this.map.getZoom() > 14),
          )
          .subscribe(() => this.resolveCenterLocation()),
      );
    }
  }

  getMap(): google.maps.Map {
    return this.map;
  }

  getBounds(): google.maps.LatLngBounds {
    return this.map.getBounds();
  }

  resolveCenterLocation(): void {
    const latLng = this.map.getCenter();

    this.geoCoder.geocode({location: latLng}, (res, code) => {
      if (code === google.maps.GeocoderStatus.OK) {
        this.zone.run(() =>
          this.addressChange.emit({
            coords: latLng,
            found: res,
          }),
        );
      } else if (code !== google.maps.GeocoderStatus.ZERO_RESULTS) {
        this.logger.error(`Geocoder error: status ${code}`, null, {
          response: res,
          code: code,
        });
      }
    });
  }

  setMapOptions(options: google.maps.MapOptions): void {
    if (options.streetViewControl === false) {
      this.map.getStreetView().setVisible(false);
    }
    this.map.setOptions(options);
  }

  addBoothMarker(location: google.maps.LatLng, id: string): void {
    this.markers.push(
      new CustomMarker(
        location,
        this.map,
        {
          marker_id: id,
          innerHTML: `<i class="icon-location-icon back"></i>
                    <i class="icon-location-icon front"></i>
                    <i class="icon-favorito-abono-icon favorite"></i>`,
        },
        this.onMarkerClick.bind(this),
        google,
        document,
      ),
    );
  }

  updateSelfMarker(location: google.maps.LatLng): void {
    if (!this.selfMarker) {
      this.selfMarker = new CustomMarker(
        location,
        this.map,
        {
          marker_id: 'selfPosition',
          innerHTML: `<div class="marker-round-wave"></div>
                    <div class="marker-round-shadow"></div>
                    <div class="marker-round"></div>`,
        },
        this.focusSelfMarker.bind(this),
        google,
        document,
        'self-marker',
      );
    } else {
      this.selfMarker.setPosition(location);
    }
  }

  /**
   * Google zoom levels:
   *
   * 20 : 1128.497220
   * 19 : 2256.994440
   * 18 : 4513.988880
   * 17 : 9027.977761
   * 16 : 18055.955520
   * 15 : 36111.911040
   * 14 : 72223.822090
   * 13 : 144447.644200
   * 12 : 288895.288400
   * 11 : 577790.576700
   * 10 : 1155581.153000
   * 9  : 2311162.307000
   * 8  : 4622324.614000
   * 7  : 9244649.227000
   * 6  : 18489298.450000
   * 5  : 36978596.910000
   * 4  : 73957193.820000
   * 3  : 147914387.600000
   * 2  : 295828775.300000
   * 1  : 591657550.500000
   */
  focusSelfMarker(zoomLevel = this.mapZoomIn): void {
    this.map.panTo(this.selfMarker.getPosition());
    this.map.setZoom(zoomLevel);

    this.selfMarker.marker.classList.add('play');
  }

  resetMarkers(): void {
    this.deleteMarkers.next();
    this.markers.forEach(marker => {
      marker.remove();
    });
    this.markers = [];

    if (this.selfMarker) {
      this.selfMarker.remove();
      this.selfMarker = null;
    }
  }

  selectMarker(id: string, center = true): void {
    this.setMarkerSelected(id, 'selected', center);
  }

  setSticky(id: string): void {
    this.setMarkerSelected(id, 'favorite', false);
  }

  zoomIn(): void {
    this.setZoomLevel(this.map.getZoom() + 1);
  }

  zoomOut(): void {
    this.setZoomLevel(this.map.getZoom() - 1);
  }

  setZoomLevel(zoom: number): void {
    this.map.setZoom(zoom);
  }

  private setMarkerSelected(
    id: string,
    className: string,
    centerMap: boolean,
  ): void {
    const markersClone = JSON.stringify([
      ...this.markers.map(m => m.args.marker_id),
    ]);
    if (this.lastMarkerIdSelected && this.lastMarkerIdSelected === id) {
      // update marker
      const lastMarkerSelected = this.markers.find(
        m => m.args.marker_id === this.lastMarkerIdSelected,
      );
      lastMarkerSelected
        .getWhenReady('GoogleMapComponent')
        .pipe(takeUntil(this.deleteMarkers))
        .subscribe({
          next: (m: CustomMarkerType) => {
            this.lastMarkerIdSelected = id;
            lastMarkerSelected.marker.classList.add(className);
            if (centerMap) {
              this.map.panTo(lastMarkerSelected.getPosition());
              this.map.panBy(0, 50);
            }
          },
          error: err => {
            this.logger.error('Error updating marker selected', err, {
              position: this.selfMarker
                ? this.selfMarker.getPosition().toJSON()
                : null,
              markers: markersClone,
              marker: id,
            });
          },
        });
    } else {
      // remove last marker if exist and set new marker
      if (this.lastMarkerIdSelected) {
        const lastMarkerSelected = this.markers.find(
          m => m.args.marker_id === this.lastMarkerIdSelected,
        );
        lastMarkerSelected
          .getWhenReady('GoogleMapComponent')
          .pipe(takeUntil(this.deleteMarkers))
          .subscribe({
            next: (m: CustomMarkerType) => {
              lastMarkerSelected.marker.classList.remove(className);
            },
            error: err => {
              this.logger.error('Error delete marker selected', err, {
                position: this.selfMarker
                  ? this.selfMarker.getPosition().toJSON()
                  : null,
                markers: markersClone,
                marker: id,
              });
            },
          });
      }

      const newMarkerSelected = this.markers.find(m => m.args.marker_id === id);
      if (!newMarkerSelected) {
        this.logger.error('Marker not found', new Error().stack, {
          position: this.selfMarker ? this.selfMarker.getPosition().toJSON() : null,
          markers: markersClone,
          marker: id,
        });
        return;
      }
      newMarkerSelected
        .getWhenReady('GoogleMapComponent')
        .pipe(takeUntil(this.deleteMarkers))
        .subscribe({
          next: (m: CustomMarkerType) => {
            this.lastMarkerIdSelected = id;
            newMarkerSelected.marker.classList.add(className);
            if (centerMap) {
              this.map.panTo(newMarkerSelected.getPosition());
              this.map.panBy(0, 50);
            }
          },
          error: err => {
            this.logger.error('Error setting new marker selected', err, {
              position: this.selfMarker
                ? this.selfMarker.getPosition().toJSON()
                : null,
              markers: markersClone,
              marker: id,
            });
          },
        });
    }
  }

  private onMarkerClick(id: string): void {
    this.selectMarker(id, !this.skipCenterOnSelection);
    this.markerSelected.emit(id);
  }
}
