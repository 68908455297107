import {Injectable, OnDestroy} from '@angular/core';
import {
  AbstractObservableDataService,
  Logger,
  PageableService,
  PaginationData,
  workerMapIfPossible,
} from 'common';
import {isToday} from 'date-fns';
import {EMPTY, iif, Observable, of, Subject} from 'rxjs';
import {
  catchError,
  expand,
  filter,
  first,
  last,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {FilteredDataService} from '../../../common/list/filtered-data-service';
import {MeasuringService} from '../../../marketing/measuring.service';
import {SessionService} from '../../../user/auth/session.service';
import {Filter} from '../../game-filter/data/filter';

import {GenericTicket} from './generic-ticket';
import {TicketsDao} from './tickets.dao';

@Injectable({providedIn: 'root'})
export class TicketsService
  extends AbstractObservableDataService<Array<GenericTicket>>
  implements
    OnDestroy,
    FilteredDataService<GenericTicket>,
    PageableService<Array<GenericTicket>>
{
  paginationIndex: number;

  paginationEnd = false;

  filter: Filter;

  private backup: Array<GenericTicket> = [];

  /**
   * Prevent two requests at the same time;
   */
  // private loadSingleRequest: Observable<List<GenericTicket>>;

  private isLoading = false;

  private abortLoad = new Subject<void>();

  constructor(
    protected ticketsDao: TicketsDao,
    protected logger: Logger,
    protected sessionService: SessionService,
    protected measuringService: MeasuringService,
  ) {
    super();
    this.sessionService.userLogoutEvent.subscribe(() => this.clear());
  }

  ngOnDestroy(): void {
    this.abortLoad.complete();
  }

  getCurrentFilter() {
    return this.filter ? this.filter.id : null;
  }

  loadMore(): Observable<Array<GenericTicket>> {
    if (this.isLoading) {
      this.abortLoad.next();
    }

    this.isLoading = true;

    return this.moreTickets().pipe(
      switchMap(tickets =>
        this._data.pipe(
          first(),
          map(current => [tickets, current]),
        ),
      ),
      switchMap((data: [PaginationData<GenericTicket>, Array<GenericTicket>]) => {
        let [tickets, current] = data;
        const currentPaginationIndex = this.paginationIndex;

        this.paginationIndex += tickets.data.length;
        this.isLoading = false;
        tickets.data = this.applyFilters(tickets.data);

        const newTickets =
          currentPaginationIndex > 0 ? current.concat(tickets.data) : tickets.data;

        const minTickets = environment.allInfo.reduce.minTickets;
        super.setData(newTickets);
        // Only set the backup if there is no filter applied
        if (!this.filter || this.filter.id === 'all') {
          this.backup = newTickets;
        }
        this.checkRepeatedTickets(newTickets);

        if (this.paginationIndex >= tickets.total || tickets.data.length === 0) {
          this.paginationEnd = true;
        } else if (
          this.paginationIndex < minTickets &&
          tickets.total > this.paginationIndex
        ) {
          // If there are not enough tickets to fill the first page, more are
          // loaded
          return this.loadMore();
        }

        return of(newTickets);
      }),
      takeUntil(this.abortLoad),
    );
  }

  reset(): Observable<Array<GenericTicket>> {
    this.paginationEnd = false;
    this.paginationIndex = 0;

    if (!(this.filter && this.filter.gameIds)) {
      const tickets = this.applyFilters(this.backup);
      this.paginationIndex = this.backup.length;
      super.setData(tickets);
      const minTickets = environment.allInfo.reduce.minTickets;
      if (tickets.length < minTickets) {
        return this.loadMore();
      }
      return of(tickets);
    } else {
      return this.loadMore();
    }
  }

  clear() {
    this.paginationEnd = false;
    this.paginationIndex = 0;
    this.backup = [];
    this.filter = null;
    super.setData([]);
  }

  getTicket(id: number): Observable<GenericTicket> {
    return this._data.pipe(map(list => list.find(t => t.id === id)));
  }

  getRecentTicket(): Observable<GenericTicket> {
    return this._data.pipe(map(tickets => tickets.find(t => isToday(t.date))));
  }

  getTicketsByBetId(betId: number): Observable<GenericTicket[]> {
    return this._data.pipe(map(tickets => tickets.filter(t => t.betId === betId)));
  }

  deleteTicket(id: number): Observable<void> {
    return this.getData().pipe(
      first(),
      map(tickets => {
        const index = tickets.findIndex(t => t.id === id);

        if (index >= 0) {
          tickets.splice(index, 1);
          super.setData([...tickets]);
        }
      }),
    );
  }

  isEmpty(): Observable<boolean> {
    return this.getData().pipe(map(list => !(list && list.length)));
  }

  /**
   * Sets tickets list value passed by param
   *
   * @param data - tickets list
   * @param totalTickets in user data - this is used to load more tickets
   * when first page is not fulfilled with existing ones
   */
  setData(data: Array<GenericTicket>, totalTickets?: number): void {
    this.backup = data;

    if (this.filter && this.filter.gameIds) {
      this.reset().subscribe();
    } else {
      this.paginationIndex = data.length;
      this.paginationEnd = false;
      const minTickets = environment.allInfo.reduce.minTickets;
      data = this.applyFilters(data);
      this.checkRepeatedTickets(data);
      super.setData(data);
      // If there are not enough tickets to fill the first page, more are loaded
      if (data.length < totalTickets && data.length < minTickets) {
        this.loadMore().subscribe();
      }
    }
  }

  /**
   * Paginates the tickets using the current filter (if any) until a non-active
   * ticket is found or the paginations ends.
   */
  loadAllActiveTickets(): Observable<Array<GenericTicket>> {
    return this.reset().pipe(
      expand(list =>
        list[list.length - 1].isActive() && !this.paginationEnd
          ? this.loadMore()
          : EMPTY,
      ),
      catchError(() => of(null)),
      last(),
      switchMap(() => this.getData().pipe(first())),
    );
  }

  /**
   * Search ticket by id in dataService and if not found, request it to the backend.
   *
   * @param id
   */
  getTicketWithFallback(id: number): Observable<GenericTicket> {
    return this.getTicket(id).pipe(
      first(),
      switchMap((ticket: GenericTicket) =>
        iif(() => !!ticket, of(ticket), this.ticketsDao.getTicket(id)),
      ),
    );
  }

  protected moreTickets(): Observable<PaginationData<GenericTicket>> {
    return this.ticketsDao.moreTickets(
      this.paginationIndex,
      this.filter ? this.filter.gameIds : undefined,
    );
  }

  private applyFilters(data: Array<GenericTicket>) {
    if (!(this.filter && this.filter.gameIds)) {
      data = data.filter(t => !t.archiveDate);
    }

    if (this.filter) {
      if (this.filter.id === 'active') {
        data = data.filter(t => t.isActive());
      } else if (this.filter.id === 'group') {
        data = data.filter(t => t.isGroup());
      }
    }

    return data;
  }

  private checkRepeatedTickets(data: Array<GenericTicket>) {
    of(data)
      .pipe(
        workerMapIfPossible((d: Array<GenericTicket>) => {
          let repeated = [];
          d.forEach(t => {
            let repeatedInLoop = [];
            d.forEach(t2 => {
              if (t.id === t2.id) {
                repeatedInLoop.push(t);
              }
            });
            if (repeatedInLoop.length > 1) {
              repeated.push(t);
            }
          });
          return repeated;
        }),
        map((repeated: Array<any>) => repeated.map(r => r.id)),
        map(ids => Array.from(new Set(ids))),
        filter(ids => ids.length > 0),
      )
      .subscribe(ids =>
        this.logger.warn('Boletos repetidos con los ids: ' + ids.toString()),
      );
  }
}
