import {Injectable} from '@angular/core';
import {catchError, map, Observable, of} from 'rxjs';
import {MeasuringDao} from './measuring.dao';

@Injectable({providedIn: 'root'})
export class MeasuringService {
  constructor(private measuringService: MeasuringDao) {}

  /**
   * Returns true if and only if the server returns ok.
   */
  sendBackendEvent(name: string): Observable<boolean> {
    return this.measuringService.sendBackendEvent(name).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  sendTagManagerEvent(name: string, options: {[x: string]: unknown} = {}) {
    options.timestamp = Date.now();
    this.measuringService.sendTagManagerEvent(name, options);
  }
}
