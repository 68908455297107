import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Logger} from 'common';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {BackendTransformable} from '../../backend/util/backend-transformable';
import {AbstractDao} from '../../common/model/abstract.dao';
import {ShareService} from '../../common/social/share.service';
import {GenericTicket} from '../tickets/data/generic-ticket';

import {addGeolocationHeaders} from '../../common/http/http';
import {SubscribableDates} from '../game-metadata/data/subscribable-date';
import {TicketDaoExtras} from './tickets-dao-extras';

@Injectable()
export class GamesDao extends AbstractDao {
  constructor(
    protected http: HttpClient,
    protected logger: Logger,
    protected shareService: ShareService,
  ) {
    super();
  }

  getTicket(ticketId: number): Observable<GenericTicket> {
    return this.http
      .get(this.baseUrl + '/users/boletos/' + ticketId)
      .pipe(map(res => GenericTicket.createFromBackend(res)));
  }

  getTicketHtml(ticketId: number): Observable<string> {
    return <Observable<string>>this.http.get(
      this.baseUrl + `/users/boletos/html/${ticketId}`,
      {
        responseType: 'text',
      },
    );
  }

  editTicket(ticketId: number, name: string, amountPlayed?: number) {
    return this.http.put(this.baseUrl + '/users/boletos/', {
      idBoleto: ticketId,
      name: name,
      almanaquePrecio: amountPlayed,
    });
  }

  share(ticket: GenericTicket, backend: BackendTransformable, asPercent = false) {
    const data = Object.assign(
      {idBoleto: ticket.id},
      backend.toBackend(ticket.originalPrice, asPercent),
    );
    return this.http
      .post(this.baseUrl + '/users/compartir', data)
      .pipe(
        tap(() =>
          data.comparticiones.forEach(shared =>
            this.shareService.incrementShareCount(shared.telefono),
          ),
        ),
      );
  }

  subscribeTicket(
    ticketId: number,
    random: boolean,
    share: boolean,
    subscribableDates?: Array<SubscribableDates>,
    minJackpotAmount?: number,
    extras?: TicketDaoExtras,
  ) {
    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    return this.http.post(
      `${this.baseUrl}/users/boleto/${ticketId}/subscribe`,
      {
        aleatorio: random,
        recompartir: share,
        abonoDays: subscribableDates?.map(subscribableDate =>
          subscribableDate.toBackend(),
        ),
        abonoMinJackpotAmount: minJackpotAmount || undefined,
      },
      {headers: headers},
    );
  }

  unsubscribeTicket(ticket: GenericTicket) {
    return this.http.delete(
      `${this.baseUrl}/users/abonos/${ticket.subscription.id}/subscription`,
    );
  }

  repeatTicket(
    ticketId: number,
    raffles: Array<number>,
    random: boolean,
    share: boolean,
    extras?: TicketDaoExtras,
  ) {
    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    return this.http.post(
      `${this.baseUrl}/users/boleto/repeat`,
      {
        idBoleto: ticketId,
        sorteoIds: raffles,
        aleatoria: random,
        recompartir: share,
      },
      {headers: headers},
    );
  }

  archiveTicket(ticketId: number) {
    return this.http.put(
      this.baseUrl + '/users/boletos/' + ticketId + '/archive',
      {},
    );
  }

  unarchiveTicket(ticketId: number) {
    return this.http.put(
      this.baseUrl + '/users/boletos/' + ticketId + '/unarchive',
      {},
    );
  }

  deleteTicket(ticketId: number) {
    return this.http.delete(this.baseUrl + '/users/boletos/' + ticketId, {});
  }

  cancelTicket(ticketId: number) {
    return this.http.put(`${this.baseUrl}/users/boletos/${ticketId}/cancel`, null);
  }

  deleteAllArchivedTickets() {
    return this.http.delete(this.baseUrl + '/users/boletos/archivedAll', {});
  }

  returnTicketPrize(ticketId: number) {
    return this.http.post(this.baseUrl + '/users/compartir/prizeReturn', {
      idBoleto: ticketId,
    });
  }

  getTicketProof(ticketId: number, userData: any) {
    return this.http.post(
      this.baseUrl + '/users/lotenal/boleto/' + ticketId + '/constancia',
      userData,
    );
  }

  downloadProof(ticketId: number) {
    return this.http.get(this.baseUrl + `/users/boleto/${ticketId}/proof`, {
      responseType: 'blob',
    });
  }

  sendProofToEmail(ticketId: number): Observable<any> {
    return this.http.get(this.baseUrl + `/users/boleto/${ticketId}/notifproof`);
  }

  saveUnfinishedRepeat(
    ticketId: number,
    raffles: Array<number>,
    random: boolean,
    share: boolean,
  ): Observable<void> {
    return this.http
      .post(this.baseUrl + '/users/unfinish/boleto/repetir', {
        idBoleto: ticketId,
        sorteoIds: raffles,
        aleatoria: random,
        recompartir: share,
      })
      .pipe(map(() => void 0));
  }
}
