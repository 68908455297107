import {
  getCurrencySymbol,
  getLocaleNumberFormat,
  NumberFormatStyle,
} from '@angular/common';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {Country} from '../countries/country';
import {CountryService} from '../countries/country.service';

import {DEFAULT_CURRENCY_CODE, DEFAULT_PHONE_CODE} from './config-tokens';
import {ENVIRONMENT} from '../environment/environment-token';

export enum CurrencyPosition {
  START,
  END,
}

@Injectable({providedIn: 'root'})
export class LocaleService {
  private localeCode: string;

  private foundPhone: Country;

  constructor(
    @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string,
    @Inject(DEFAULT_PHONE_CODE) private defaultPhoneCode: string,
    @Inject(LOCALE_ID) private defaultLocale: string,
    private countryService: CountryService,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {
    this.localeCode = this.defaultLocale;
  }

  // noinspection JSMethodCanBeStatic
  getCurrencyCode(): string {
    return getCurrencySymbol(this.currencyCode, 'wide');
  }

  // noinspection JSMethodCanBeStatic
  getCurrencySymbol(): string {
    return getCurrencySymbol(this.currencyCode, 'narrow');
  }

  /** @returns boolean indicated that wants to reload app*/
  setLocale(code: string) {
    if (this.localeCode !== code) {
      this.localeCode = code;
    }
    // TODO - MULTILENGUAGE
    // De momento lo guardamos en local pero habría que guardarlo en los datos extra del usuario
    localStorage.setItem(this.environment.localStorageKeys.userLocale, code);
    // TODO - MULTILENGUAGE

    // Para cuando se pueda cambiar el idioma con la sesión cerrada
    localStorage.removeItem(this.environment.localStorageKeys.allInfo);
  }

  getLocale(): string {
    const savedUserLocale = localStorage.getItem(
      this.environment.localStorageKeys.userLocale,
    );
    if (savedUserLocale && savedUserLocale !== this.localeCode) {
      this.localeCode = savedUserLocale;
    }
    return this.localeCode;
  }

  getLocaleAbbr() {
    return this.localeCode.split('-')[0];
  }

  getCurrencySymbolPosition() {
    return getLocaleNumberFormat(
      this.localeCode,
      NumberFormatStyle.Currency,
    ).startsWith('¤')
      ? CurrencyPosition.START
      : CurrencyPosition.END;
  }

  symbolHasSpace(): boolean {
    const pattern = getLocaleNumberFormat(
      this.localeCode,
      NumberFormatStyle.Currency,
    );
    return pattern.startsWith('¤ ') || pattern.endsWith(' ¤');
  }

  getDefaultPhonePrefix(): Observable<Country> {
    return this.countryService.getData().pipe(
      first(),
      map((countries: Array<Country>) => {
        if (!this.foundPhone) {
          this.foundPhone = countries.find(
            country => country.code === this.defaultPhoneCode,
          );
        }
        return this.foundPhone;
      }),
    );
  }
}
