<tl-modal-dialog
  type="ok_only"
  class="tl-limit-reached-modal__dialog"
  [closeOnNavigation]="true"
  [accept]="{key: 'selfExclusion.dialogLimit.okButton'}">
  <img class="tl-limit-reached-modal__image" [src]="image" />
  <p
    class="dark-default tl-limit-reached-modal__title"
    [innerHtml]="titleKey | i18nData: titleKeyData | htmlSafe"></p>
  <p
    *ngIf="spentKey"
    class="tl-limit-reached-modal__remaining"
    [innerHtml]="spentKey | i18nData: spentKeyData | htmlSafe"></p>
  <tl-translatable-text
    [text]="{key: hintKey, keyData: hintKeyData}"></tl-translatable-text>
</tl-modal-dialog>
