import {Serializable, SerializableProperty, SerializableType} from 'common';

import {environment} from '~environments/environment';

import {AbstractGameTypeMetadata} from './abstract-game-type-metadata';
import {AdditionalBetPicksBetRuleTypeMetadata} from './additional-bet-picks-bet-rule-type-metadata';
import {BetMetadata} from './bet-metadata';
import {GAME_TYPE_METADATA_RESOLVER} from './game-type-metadata-resolver';
import {PickedMultiplesBetRuleTypeMetadata} from './picked-multiples-bet-rule-type-metadata';
import {RequiredBetRuleTypeMetadata} from './required-bet-rule-type-metadata';
import {RequiredMultiplesBetRuleTypeMetadata} from './required-multiples-bet-rule-type-metadata';
import {UIMetadata} from './ui-metadata';
import {SubscribableDates} from './subscribable-date';
import {SubscribableJackpot} from './subscribable-jackpot';
import {Provider} from './provider';
import {SearchLotteryType} from './search-lottery-type';

class GameMetadataInternal {
  id: string;

  name: string;

  upgrade: boolean;

  metadataType: string;

  version: number;

  price: number;

  /**
   * Used for lottery games (in mx) to decide if we have to search and play a
   * ticket or the server finds one from just a request with the amount.
   */
  serverRandom: boolean;

  /**
   * true to use LotenalLotterySearchService false for SelaeLotterySearchService
   */
  customSearch: boolean;

  /**
   * Can play several raffles at the same time.
   */
  multiRaffle: boolean;

  /**
   * Has more than one raffle per day, mainly for dates visualization
   */
  multiRaffleDay: boolean;

  subscribable: boolean;

  shareable: boolean;

  jackpot: boolean;

  /**
   * If true allows playing different betTypes in each panel
   */
  multipleBetTypes: boolean;

  showOnlyOneDraw: boolean;

  /**
   * True to send the id of each panel in the play payload
   */
  includeBoardId: boolean;

  /**
   * Raffles that only have one ticket per number
   */
  singleNumbers: boolean;

  provider: Provider | string;

  playableOnGroups: boolean;

  playableOnStates: Array<string>;

  visibleOnStates: Array<string>;

  @SerializableProperty(
    json => GAME_TYPE_METADATA_RESOLVER[json.type],
    SerializableType.MAP,
    true,
  )
  types: Map<string, AbstractGameTypeMetadata>;

  @SerializableProperty(BetMetadata, SerializableType.MAP_OF_MAP)
  bets: Map<string, Map<string, BetMetadata>>;

  @SerializableProperty(UIMetadata, SerializableType.OBJECT)
  uiMetadata: UIMetadata;

  @SerializableProperty(SubscribableDates, SerializableType.COLLECTION)
  subscribableDates: Array<SubscribableDates>;

  @SerializableProperty(SubscribableJackpot, SerializableType.OBJECT)
  subscribableJackpot: SubscribableJackpot;

  allowOddBets: boolean;

  /**
   * If true, the user can select a booth to play. Used on some lottery games.
   */
  allowSelectBoothId: boolean;

  searchLotteryType: SearchLotteryType;

  hasMedia: boolean;

  getTypeMetadata(id: string): AbstractGameTypeMetadata {
    return this.types.get(id);
  }

  getBetMetadata(id: string, group = 'default'): BetMetadata {
    return this.bets.get(group).get(id);
  }

  getBetMaxPanels(id: string, group = 'default'): number {
    return this.getBetMetadata(id, group).rules[0].maxPanels;
  }

  getFirstBetMinPanels(group = 'default'): number {
    return this.getFirstBet(group).rules[0].minPanels;
  }

  getFirstBetMaxPanels(group = 'default'): number {
    return this.getFirstBet(group).rules[0].maxPanels;
  }

  /**
   * The caller param needs to be removed once bug WEB-1029 is resolved
   *
   * @param group The value from formGroup
   * @param caller The function calling
   * @returns Bet info
   */
  getFirstBet(group = 'default', caller = ''): BetMetadata {
    return this.bets.get(group).values().next().value;
  }

  getFreeModeBet(group = 'default'): BetMetadata {
    return Array.from(this.bets.get(group).values()).find(bet =>
      bet.hasVariableMultiplier(),
    );
  }

  getFirstType(): AbstractGameTypeMetadata {
    return this.types.values().next().value;
  }

  getTypeKeys(): Array<string> {
    return Array.from(this.types.keys());
  }

  getForcedLocalTypes() {
    return Array.from(this.types.values()).filter(
      type => !!type.uiMetadata && type.uiMetadata.displayAsLocal,
    );
  }

  getUnitLabel(): string {
    return (this.uiMetadata && this.uiMetadata.unitLabel) || '';
  }

  getFractionLabel(): string {
    return (this.uiMetadata && this.uiMetadata.fractionLabel) || '';
  }
}

export class GameMetadata extends Serializable(GameMetadataInternal) {
  static fromJSON(json: any): GameMetadata {
    const gameMD = super.fromJSON(json);
    if (json !== undefined && json !== null) {
      gameMD.types.forEach(type => {
        if (type.dependsOn) {
          type.dependsOn = gameMD.types.get(type.dependsOn.id);
        }
      });

      gameMD.bets.forEach(group =>
        group.forEach(bet =>
          bet.rules.forEach(rule =>
            rule.ruleTypes.forEach(ruleType => {
              ruleType.type = gameMD.types.get(ruleType.type.id);

              if (ruleType instanceof PickedMultiplesBetRuleTypeMetadata) {
                const sourceRuleType = bet.getRuleTypeForType(
                  ruleType.multiplesSourceType.type.id,
                );

                ruleType.multiplesSourceType = <
                  RequiredMultiplesBetRuleTypeMetadata
                >sourceRuleType;
              } else if (ruleType instanceof AdditionalBetPicksBetRuleTypeMetadata) {
                const sourceRuleType = bet.getRuleTypeForType(
                  ruleType.betsSourceType.type.id,
                );

                ruleType.betsSourceType = <RequiredBetRuleTypeMetadata>(
                  sourceRuleType
                );
              }
            }),
          ),
        ),
      );
    }

    return gameMD;
  }

  static createFromBackend(obj: any) {
    let gameMetadata = new GameMetadata();

    gameMetadata.id = obj.juego;
    gameMetadata.name = obj.name;
    gameMetadata.upgrade = !!obj.upgrade;
    gameMetadata.metadataType = obj.descriptorType;
    gameMetadata.version = obj.juegoVersion;
    gameMetadata.price = obj.precioApuesta;
    gameMetadata.serverRandom = obj.randomInServer;
    // TODO · PROVISIONAL? · this means lotenal-like search, should come from
    // server or set based on game
    gameMetadata.customSearch =
      obj.descriptorType === 'lottery' &&
      environment.games.lottery.allowCustomSearch;
    // -----
    gameMetadata.multiRaffle = obj.allowMultipleDates;
    gameMetadata.multiRaffleDay = obj.drawsOnSameDay;
    gameMetadata.subscribable = obj.allowAbono;
    gameMetadata.shareable = obj.allowShare;
    gameMetadata.jackpot = obj.hasJackpot;
    gameMetadata.multipleBetTypes = obj.allowDifferentBetIds;
    gameMetadata.showOnlyOneDraw = obj.showOnlyOneDraw;
    gameMetadata.provider = obj.integrator;
    gameMetadata.singleNumbers = obj.raffleGame;
    gameMetadata.playableOnGroups = obj.playableOnGroups;
    gameMetadata.playableOnStates = obj.playableOnStates;
    gameMetadata.visibleOnStates = obj.visibleOnStates;
    gameMetadata.includeBoardId = !!obj.includeBoardId;
    gameMetadata.hasMedia = obj.hasMedia;

    gameMetadata.types = obj.types
      ? new Map(
          obj.types.map(type => [
            type.typeId,
            GAME_TYPE_METADATA_RESOLVER[type.type].createFromBackend(type),
          ]),
        )
      : undefined;

    gameMetadata.bets = new Map();

    if (!obj.groups) {
      gameMetadata.bets.set(
        'default',
        new Map(
          obj.bets.map(bet => [bet.betId, BetMetadata.createFromBackend(bet)]),
        ),
      );
    } else {
      obj.groups.forEach(group =>
        gameMetadata.bets.set(
          group,
          new Map(
            obj.bets
              .filter(
                bet =>
                  (bet.groups && bet.groups.includes(group)) ||
                  (!bet.groups && group === 'default'),
              )
              .map(bet => [bet.betId, BetMetadata.createFromBackend(bet)]),
          ),
        ),
      );
    }

    gameMetadata.uiMetadata = obj.uiInfo
      ? UIMetadata.createFromBackend(obj.uiInfo, gameMetadata)
      : undefined;

    obj.types
      .filter(type => type.dependsOn)
      .forEach(type => {
        gameMetadata.types.get(type.typeId).dependsOn = gameMetadata.types.get(
          type.dependsOn,
        );
      });

    // set gametypes inside ruletypes and playablility from bettypes

    obj.bets.forEach(bet =>
      bet.types.forEach((rule, rIdx) =>
        rule.typeIds.forEach((ruleType, rtIdx) => {
          const type = gameMetadata.types.get(ruleType.typeId);
          type.playable = true;

          (bet.groups || ['default']).forEach(group => {
            const parsedBet = gameMetadata.bets.get(group).get(bet.betId);
            const currentRuleType = parsedBet.rules[rIdx].ruleTypes[rtIdx];
            currentRuleType.type = type;

            // link SelectionPicks rules to their source rule
            if (currentRuleType instanceof PickedMultiplesBetRuleTypeMetadata) {
              const sourceRuleType = parsedBet.getRuleTypeForType(
                ruleType.multiplesSourceType,
              );

              currentRuleType.multiplesSourceType = <
                RequiredMultiplesBetRuleTypeMetadata
              >sourceRuleType;
            } else if (
              currentRuleType instanceof AdditionalBetPicksBetRuleTypeMetadata
            ) {
              const sourceRuleType = parsedBet.getRuleTypeForType(
                ruleType.betsSourceType,
              );

              currentRuleType.betsSourceType = <RequiredBetRuleTypeMetadata>(
                sourceRuleType
              );
            }
          });
        }),
      ),
    );

    // fill default layouts (server only sends custom ones)
    gameMetadata.uiMetadata.layout =
      obj.uiInfo && obj.uiInfo.playLayout
        ? obj.uiInfo.playLayout
        : gameMetadata.metadataType;

    gameMetadata.subscribableDates =
      obj.abonoAllowedDays &&
      obj.abonoAllowedDays.map(SubscribableDates.createFromBackend);

    gameMetadata.subscribableJackpot =
      obj.abonoPredefined &&
      SubscribableJackpot.createFromBackend(obj.abonoPredefined);

    gameMetadata.allowOddBets = obj.allowOddBets;
    gameMetadata.allowSelectBoothId =
      gameMetadata.metadataType === 'lottery' &&
      environment.games.lottery.allowSelectBoothId.includes(gameMetadata.id);

    gameMetadata.searchLotteryType =
      gameMetadata.metadataType === 'lottery' &&
      environment.games.lottery.searchLotteryType;

    return gameMetadata;
  }

  static createMinimalMetadata(metadata: GameMetadata): GameMetadata {
    const newMeta = GameMetadata.fromJSON(
      JSON.parse(JSON.stringify(metadata)) as any,
    );

    newMeta.multiRaffle = false;
    newMeta.subscribable = false;

    const simpleBet = newMeta.getFirstBet('default');
    newMeta.bets = new Map([['default', new Map([[simpleBet.id, simpleBet]])]]);

    simpleBet.maxMultiplier = simpleBet.minMultiplier;

    simpleBet.rules = simpleBet.rules.filter(rule => {
      const optional = rule.ruleTypes.reduce(
        (op, type) => type.optional && op,
        true,
      );

      if (!optional) {
        rule.maxPanels = rule.minPanels;
        rule.ruleTypes.forEach(ruleType => {
          if (ruleType.hasOwnProperty('maxMultiplier')) {
            ruleType['maxMultiplier'] = ruleType['minMultiplier'];
          }
        });
      }

      return !optional;
    });

    return newMeta;
  }
}
