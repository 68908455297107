import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger, ResponsiveService} from 'common';
import {merge, Observable, of, ReplaySubject, Subject, zip} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {BoothSelectedService} from '../../../../booth/booth-selected.service';
import {ShippingAddressFormGroup} from '../../../../shipment/model/shipping-address-form-group';
import {TicketShippingService} from '../../../../shipment/model/ticket-shipping.service';
import {UserService} from '../../../../user/data/user.service';
import {BetMetadata} from '../../../game-metadata/data/bet-metadata';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {LotteryTicket} from '../../../lottery/data/lottery-ticket';
import {ShareFormGroup} from '../../../share/model/share-form-group';
import {DynamicTicketSearchService} from '../di/dynamic-ticket-search.service';
import {RafflesPlayService} from '../raffles-play.service';
import {AbstractLotterySearch} from '../search/abstract-lottery-search';
import {PlayShippingService} from '../shipping/play-shipping.service';

import {CombinationForm} from './combination-form';
import {FormService} from './form.service';
import {LotteryBoothService} from '../../../../booth/data/lottery-booth.service';
import {LotteryBooth} from '../../../../booth/data/lottery-booth';
import {User} from '../../../../user/data/user';
import {GameId} from '../../../data/game-id';
import {Provider} from '../../../game-metadata/data/provider';
import {environment} from '~environments/environment';
import {LotteryService} from './lottery-service';
import {isShippingService} from './lottery-service';
import {BetRuleTypeMetadata} from '../../../game-metadata/data/bet-rule-type-metadata';

@Injectable()
export class LotteryFormService extends FormService implements OnDestroy {
  noTicketsInFavouriteBooth = new EventEmitter<void>();
  noEnoughtTicketsInFavouriteBooth = new EventEmitter<number>();

  randomStock = new ReplaySubject<{stock: number; boothId: string}>(1);

  addressForm: ShippingAddressFormGroup | null = null;

  errorShippmentDisabled: string;

  get selectedBoothId(): string {
    try {
      return this.form.value.selectedBoothId;
    } catch (e) {
      // https://tulotero.atlassian.net/browse/WEB-3623
      this.logger.error('WEB-3623 the form was not created', e.stack, {
        formData: this,
      });
    }
    return null;
  }

  set selectedBoothId(boothId: string) {
    this.form.get('selectedBoothId').setValue(boothId);
  }

  get currentService(): string {
    return this.form?.value.service;
  }

  get isShippingService(): boolean {
    return [LotteryService.SHIPMENT, LotteryService.COLLECTION].includes(
      this.form.value.service,
    );
  }

  private betMetadata: BetMetadata;

  private destroySubject = new Subject<void>();

  private favouriteBooth: string;

  private searchService: AbstractLotterySearch;

  // For exclude favourite booth when the user has no tickets in the favourite booth
  private favouriteBoothBackup: string | undefined;

  // For exclude booth when the user has no tickets and the user has no favourite booth
  private lastBoothSelected: string | undefined;

  // Only used on games with randomInServer = false (ex. Loteria Nacional)
  private maxBets: Observable<number>;

  constructor(
    private boothSelectedService: BoothSelectedService,
    private boothsService: LotteryBoothService,
    protected fb: FormBuilder,
    private dynamicSearchService: DynamicTicketSearchService,
    protected logger: Logger,
    private playShippingService: PlayShippingService,
    private rafflesPlay: RafflesPlayService,
    private responsiveService: ResponsiveService,
    private shippingService: TicketShippingService,
    private userService: UserService,
  ) {
    super(fb, logger);
    this.maxBets = this.randomStock.pipe(
      map(({stock}) => stock),
      takeUntil(this.destroySubject),
      shareReplay(1),
    );
  }

  create(withDefaultBet = true): void {
    super.create(withDefaultBet);

    this.addressForm = null;

    this.resetFavouriteBooth();

    if (this.gameMetadata.allowSelectBoothId) {
      this.form.addControl('selectedBoothId', this.fb.control(null));
    }
    if (this.gameMetadata.id === GameId.LOTERIA_NACIONAL) {
      this.shippingService.form.get('ship').setValue(false);

      this.form.addControl('service', this.fb.control(LotteryService.DIGITAL));
      this.mainFormSubscriptions.push(
        this.form
          .get('service')
          .valueChanges.subscribe(serviceValue =>
            this.shippingService.form
              .get('ship')
              .setValue(serviceValue === LotteryService.SHIPMENT),
          ),
      );

      this.mainFormSubscriptions.push(
        this.form
          .get('random')
          .valueChanges.pipe(
            filter(() => this.currentService === LotteryService.COLLECTION),
          )
          .subscribe(random => {
            this.form
              .get('selectedBoothId')
              .setValidators(random ? Validators.required : null);
            this.form.get('selectedBoothId').updateValueAndValidity();
          }),
      );
    }
  }

  enableShipment(): void {
    this.addressForm = this.shippingService.form.address;

    this.shippingService.fillShipmentAdressForForm(this.addressForm);
    this.form.addControl('address', this.addressForm);

    this.mainFormSubscriptions.push(
      this.addressForm.statusChanges
        .pipe(
          filter(state => state === 'VALID'),
          map(() => this.addressForm.get('stateZipCode.state').value),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.playShippingService.boothsShipmentCost.clear();
        }),
    );

    this.playShippingService.init(this.form, this.randomStock);
  }

  restore(defaults: any): void {
    this.form.get('random').reset(defaults.random);

    super.restore(defaults);
    if (isShippingService(defaults.service)) {
      const address = this.shippingService.form.address;
      address.reset(defaults.address);
      this.addressForm = address;
      this.form.addControl('address', address);
      this.playShippingService.init(this.form, this.randomStock);
    }

    this.clearCombinations();
    if (!this.form.get('random').value) {
      this.setCombinationValidators();
    }
    defaults.bet.combinations.forEach(combination => {
      let combinationForm = this.createCombinationFormGroup();
      combinationForm.reset(combination);

      (this.form.get('bet.combinations') as FormArray).push(combinationForm);
    });
  }

  setService(service: string) {
    this.form.get('service').setValue(service);
  }

  createCombinationFormGroup(index?: number): CombinationForm {
    let combinationForm = new CombinationForm({
      amount: this.fb.control(undefined, [
        Validators.required,
        Validators.min(this.betMetadata.minMultiplier),
        Validators.max(this.betMetadata.maxMultiplier),
      ]),
      externalPrice: this.fb.control(undefined),
      value: this.fb.group({}),
      hash: this.fb.control(null, Validators.required),
    });

    this.betMetadata.rules
      .filter(rule => rule.scope === 'local')
      .forEach(rule =>
        rule.ruleTypes.forEach(ruleType => {
          // assume selection (if exists) can't be multiple here
          (combinationForm.get('value') as FormGroup).addControl(
            ruleType.type.id,
            this.fb.control(null),
          );
          this.setFieldValidators(
            combinationForm.get(`value.${ruleType.type.id}`),
            ruleType,
          );
        }),
      );

    return combinationForm;
  }

  clearCombination(combinationForm: FormGroup): void {
    combinationForm.reset();
  }

  clearForcedLocalGlobals() {
    this.logger.warn(
      'Method clearForcedLocalGlobals not supported in' + 'LotteryFormService',
    );
  }

  generateRandomCombination(form: FormGroup): FormGroup {
    this.logger.warn(
      'Method generateRandomCombination not supported in' + 'LotteryFormService',
    );
    return form;
  }

  generateRandomForcedLocalGlobals(): void {
    this.logger.warn(
      'Method generateRandomForcedLocalGlobals not supported in' +
        'LotteryFormService',
    );
  }

  toBackend(): Record<string, any> {
    let combinations = this.form.get('bet.combinations') as FormArray;
    let betsValue;

    if (this.form.get('random').value && this.gameMetadata.serverRandom) {
      betsValue = [
        {
          amountBet: this.form.get('betsNumber').value,
          betId: this.betMetadata.id,
          types: [],
        },
      ];
    } else {
      betsValue = combinations.value.map(combination => ({
        amountBet: combination.amount,
        searchAdminId: combination.searchAdminId,
        betId: this.betMetadata.id,
        types: this.betMetadata.rules[0].ruleTypes
          .filter(
            (ruleType: BetRuleTypeMetadata) => !!combination.value[ruleType.type.id],
          )
          .map((ruleType: BetRuleTypeMetadata) => {
            let value = combination.value[ruleType.type.id];

            if (ruleType.type.type === 'SELECTION') {
              value = {selections: [{value: value[0]}]};
            } else {
              value = Array.isArray(value) ? value[0] : value;
            }

            return {
              type: ruleType.type.playType,
              typeId: ruleType.type.id,
              value: [value],
            };
          }),
      }));
    }

    const almanaque = this.form.get('external').value;
    let externalPrice;
    if (
      this.form.value.bet.combinations &&
      this.form.value.bet.combinations.length > 0
    ) {
      externalPrice = this.form.value.bet.combinations[0].externalPrice;
    }

    let payload = {
      almanaque: almanaque,
      confirmacion: false,
      abonarse: this.form.get('subscribe').value,
      abonoDays: !this.form.get('subscriptionType').value
        ? this.buildSubscribableDatesField()
        : null,
      abonoMinJackpotAmount: this.form.get('subscriptionType').value
        ? this.buildSubscribableJackpotField()
        : undefined,
      aleatorio: this.form.get('random').value,
      groupIdToSpoof: this.form.get('groupId').value ?? undefined,
      juego: this.gameMetadata.id,
      quickPlay: this.form.get('quickplay').value,
      sorteoIds: Array.isArray(this.form.get('raffles').value)
        ? this.form.get('raffles').value
        : [this.form.get('raffles').value],
      numApuestas: this.form.get('betsNumber').value,
      totalApuesta: almanaque ? externalPrice : this.totalPrice,
      comparticion: this.buildShareField(),
      extraInfo: this.extraInfoToBackend(),
      combinacionJugada: {
        aleatoria: this.form.get('random').value,
        juego: this.gameMetadata.id,
        bets: betsValue,
      },
    };

    if (
      (this.selectedBoothId ?? this.favouriteBooth) &&
      this.form.get('random').value
    ) {
      payload['boothId'] = this.selectedBoothId ?? this.favouriteBooth;
    }

    // Avoid cancel play when shipping is not available (Ex: Ceuta)
    if (!this.errorShippmentDisabled && this.form.contains('service')) {
      if ([LotteryService.SHIPMENT].includes(this.form.get('service').value)) {
        payload['envioCasaRequest'] = this.addressForm.toBackend();
      }

      if ([LotteryService.COLLECTION].includes(this.form.get('service').value)) {
        payload['recogida'] = true;
      }
    }
    return payload;
  }

  generateRandomBet(): void {
    this.clearCombinations();
  }

  setMetadata(gameMetadata: GameMetadata): void {
    super.setMetadata(gameMetadata);
    this.betMetadata = gameMetadata.getFirstBet('default');
    this.searchService = this.dynamicSearchService.getInstance(this.gameMetadata);
  }

  getMinimumBets(): number {
    return this.betMetadata.rules[0].minPanels;
  }

  getMaximumBets(): Observable<number> {
    let maxBets: number = this.betMetadata.getLocalRule().maxPanels;
    if (this.gameMetadata.customSearch) {
      // lotenal-like logic
      // TODO BORRAR. Mientras no se hace el cambio en backend para permitir en modo random
      //  comprar varios decimos sin ser del mismo numero. Se limita a GRAN ESPECIAL porque
      //  es el unico caso que solo tiene 1 serie (cachito) por numero.
      if (this.gameMetadata.id === 'GRANESPECIAL') {
        maxBets = 1;
      }
    } else if (!this.gameMetadata.serverRandom) {
      return this.maxBets;
    }
    return of(maxBets);
  }

  getMaximumNumbers(): number {
    const betMetadata = this.gameMetadata.getBetMetadata(
      this.form.get('bet.currentBetType').value,
      this.form.get('betGroup').value,
    );
    let maxBets: number = betMetadata.getLocalRule().maxPanels; // Example: Loteria Nacional
    if (this.gameMetadata.customSearch) {
      maxBets = betMetadata.getLocalRule().maxPanels; // Example MX (except Lotenal)
      // lotenal-like logic
      if (this.gameMetadata.provider === Provider.LOTENAL) {
        // To calculate the number of different numbers that can be played
        // you have to divide maxMultiplier / maxPanels.
        // In backend 'maxPanels' is 'numMaxColumns' and 'maxMultiplier' is 'multBetMax'
        // Rounding down in case of decimals although this should never happen.
        maxBets = Math.floor(
          this.betMetadata.maxMultiplier / this.betMetadata.getLocalRule().maxPanels,
        );
      }
    }
    return maxBets;
  }

  getBetType(): string {
    return this.form.get('bet.currentBetType').value;
  }

  setBetType(betId: string) {
    this.form.get('bet.currentBetType').setValue(betId);
  }

  createCombinationFromTicket(ticket: LotteryTicket, fraction?: string): FormGroup {
    let combinationForm = this.createCombinationFormGroup();
    ticket.value.forEach(type => {
      let normalizedValue = Array.isArray(type.value[0])
        ? type.value[0][0].value
        : (type.value[0] as any).value;
      if (type.typeId === 'fraccion' && fraction) {
        combinationForm.get(`value.${type.typeId}`).setValue([fraction]);
      } else {
        combinationForm.get(`value.${type.typeId}`).setValue([normalizedValue]);
      }
    });

    combinationForm.get('hash').setValue(ticket.hash);

    return combinationForm;
  }

  clearFavouriteBooth(): void {
    this.favouriteBooth = undefined;
  }

  getFavouriteBooth(): string {
    return this.favouriteBooth;
  }

  generateRandomGlobals(): void {
    // not supported
  }

  clearGlobals(): void {
    // not supported
  }

  resetFavouriteBooth(): void {
    this.userService
      .getData()
      .pipe(
        first(),
        filter((user: User) => !!user),
        switchMap((user: User) =>
          // We check if the booth exists.
          // This is for the case in which the booth has been deleted
          // but was a favorite in the user.
          this.boothsService.getLotteryBooth(user.lotteryBooth),
        ),
      )
      .subscribe((booth: LotteryBooth) => {
        this.favouriteBoothBackup = booth?.id;
        this.favouriteBooth = booth?.id;
      });
  }

  stateRandom(forceBoothSelection?: boolean): void {
    this.clearCombinations();

    this.form.get('bet.combinations').setValidators([]);
    this.form.get('bet.combinations').updateValueAndValidity();

    if (!this.gameMetadata.serverRandom || forceBoothSelection) {
      this.form
        .get('raffles')
        .valueChanges.pipe(
          distinctUntilChanged(),
          startWith(this.form.get('raffles').value),
          filter(raffleId => !!raffleId),
          switchMap(raffleId => {
            this.searchService.setRaffleId(raffleId);
            return this.getStockBoothId(forceBoothSelection);
          }),
          switchMap((boothId: string) =>
            zip(
              this.searchService.getStock(boothId),
              this.boothSelectedService.selectedBooth,
            ),
          ),
          takeUntil(merge(this.stateChange, this.destroySubject)),
        )
        .subscribe(([randomStock, selectedBooth]) => {
          const allowChangeRandomBoothOnDelivery =
            environment.features.games.lottery.allowChangeRandomBoothOnDelivery;
          this.randomStock.next(randomStock);
          this.setStockLimits(randomStock.stock);
          this.lastBoothSelected = randomStock.boothId;

          if (randomStock.stock === 0) {
            this.closed.next(true);
          } else {
            if (
              this.currentService === LotteryService.COLLECTION
                ? this.selectedBoothId && randomStock.boothId !== selectedBooth.id
                : this.favouriteBooth && this.favouriteBooth !== randomStock.boothId
            ) {
              this.noTicketsInFavouriteBooth.emit();
              this.clearFavouriteBooth();
            } else if (
              this.currentService !== LotteryService.COLLECTION &&
              this.favouriteBooth === randomStock.boothId
            ) {
              this.form
                .get('betsNumber')
                .valueChanges.pipe(takeUntil(this.destroySubject))
                .subscribe(betsNumber => {
                  if (betsNumber > randomStock.stock) {
                    this.noEnoughtTicketsInFavouriteBooth.next(randomStock.stock);
                    this.clearFavouriteBooth();
                  } else {
                    if (betsNumber <= randomStock.stock) {
                      this.resetFavouriteBooth();
                    }
                  }
                });
            } else if (
              allowChangeRandomBoothOnDelivery &&
              !this.favouriteBooth &&
              randomStock.boothId &&
              this.currentService === LotteryService.SHIPMENT
            ) {
              this.selectedBoothId = randomStock.boothId;
            }
          }
        });
    } else {
      this.getMaximumBets()
        .pipe(first())
        .subscribe(maxBets => this.setStockLimits(maxBets));
    }
  }

  /**
   * Send the current admin to exclude and the lottery numbers you need for backend
   * to return an admin that meets those requirements.
   */
  changeBooth(): Observable<{stock: number; boothId: string}> {
    return this.searchService
      .getStockExcludeBooth(
        this.form.get('selectedBoothId').value ??
          this.favouriteBooth ??
          this.favouriteBoothBackup ??
          this.lastBoothSelected,
        this.form.get('betsNumber').value,
      )
      .pipe(
        // Check if the booth exists. Sometimes the backend returns inactive booths
        switchMap((randomStock: {stock: number; boothId: string}) =>
          this.boothsService.getLotteryBooth(randomStock.boothId).pipe(
            switchMap((booth: LotteryBooth) => {
              if (!booth) {
                this.logger.error(`Booth ${randomStock.boothId} not found`);
                // Return last valid admin-stock
                return this.randomStock.pipe(first());
              }
              return of(randomStock);
            }),
          ),
        ),
        map((randomStock: {stock: number; boothId: string}) => {
          this.selectedBoothId = randomStock.boothId;
          this.clearCombinations();
          this.randomStock.next(randomStock);
          return randomStock;
        }),
      );
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.shippingService.clearService();

    super.ngOnDestroy();
  }

  protected createBetForm() {
    this.form.setControl(
      'bet',
      this.fb.group({
        currentBetType: [this.betMetadata.id, Validators.required],
        combinations: this.fb.array(
          [],
          Validators.compose([Validators.required, Validators.minLength(1)]),
        ),
        autoFields: this.fb.group({}),
        extraFields: this.fb.group({}),
      }),
    );

    this.mainFormSubscriptions.push(
      this.form.get('raffles').valueChanges.subscribe(() => {
        this.clearCombinations();
        this.resetFavouriteBooth();
      }),
    );

    this.form.get('random').updateValueAndValidity();
  }

  /**
   * In lottery share is totalPrice / total number of tickets user buys.
   * But if user buys 2 ticket of same number and 1 ticket of another number,
   * sharing fails due to a backend bug. We cannot send diffent prices when 2
   * user tickets are generated. It will be fixed by backend in the future.
   *
   * @link https://trello.com/c/lYnswMke
   *
   **/
  protected buildShareField(): Record<string, unknown> | undefined {
    return (<ShareFormGroup>this.form.get('shares')).toBackend(
      this.totalPrice / this.form.get('betsNumber').value,
    );
  }

  protected calculatePrice() {
    if (this.form.get('external').value) {
      return;
    }

    if (
      !this.isFormValidForPrice() ||
      this.form.get('promo').value === 'prereserveshared'
    ) {
      this.price.next(0);
      return;
    }

    // TODO forms-refactor
    this.rafflesPlay
      .getData()
      .pipe(
        map(l => {
          let r = l.find(raffle => raffle.id === this.form.get('raffles').value);
          if (r) {
            return r.price;
          } else {
            this.logger.warn(
              'Se ha intentado calcular el precio de la ' +
                `raffle ${this.form
                  .get('raffles')
                  .value.toString()} pero no se ha encontrado`,
              {raffles: l, current: this.form.get('raffles').value},
            );
            return 0;
          }
        }),
        first(),
      )
      .subscribe(price => {
        // assume lottery can't have optional types and/or extra price on types
        this.price.next(price * this.form.get('betsNumber').value);
      });
  }

  protected setCalculatePriceSubscription(): void {
    this.mainFormSubscriptions.push(
      this.form.statusChanges.pipe(debounceTime(80)).subscribe(() => {
        this.calculatePrice();
        if (this.isShippingService) {
          if (!this.playShippingService.isInitialized.getValue()) {
            this.enableShipment();
          }
          this.playShippingService.isInitialized
            .asObservable()
            .pipe(
              filter(initialized => initialized),
              first(),
            )
            .subscribe(() => this.playShippingService.calculateShipmentCosts());
        }
      }),
    );
  }

  protected stateManual(): void {
    this.clearCombinations();
    if (this.gameMetadata.allowSelectBoothId) {
      this.selectedBoothId = null;
    }
    if (!this.gameMetadata.serverRandom) {
      // Ex: There is no betting limit for the Loteria Nacional, the limit is by different numbers
      this.setStockLimits(Infinity);
    } else {
      this.getMaximumBets()
        .pipe(first())
        .subscribe(maxBets => this.setStockLimits(maxBets));
    }

    this.form
      .get('bet.combinations')
      .valueChanges.pipe(takeUntil(this.stateChange), takeUntil(this.destroySubject))
      .subscribe(() => {
        let number = this.form
          .get('bet.combinations')
          .value.reduce((total, current) => total + current.amount, 0);

        this.form.get('betsNumber').setValue(number);
      });

    this.setCombinationValidators();
    this.form.get('bet.combinations').updateValueAndValidity();
  }

  protected setCombinationValidators(): void {
    this.form
      .get('bet.combinations')
      .setValidators([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(this.getMaximumNumbers()),
      ]);
  }

  private isFormValidForPrice(): boolean {
    if (this.currentService === LotteryService.SHIPMENT) {
      return Object.keys(this.form.controls)
        .filter(key => key !== 'address')
        .map(key => this.form.get(key))
        .reduce((valid, control) => valid && control.valid, true);
    }

    return this.form?.valid;
  }

  private setStockLimits(stock: number): void {
    this.addBetsNumberValidation(
      Validators.compose([Validators.min(1), Validators.max(stock)]),
    );
    this.form.get('betsNumber').setValue(this.betMetadata.rules[0].minPanels);
  }

  private getStockBoothId(forceBoothSelection: boolean): Observable<string> {
    let stockBoothId = of(this.selectedBoothId ?? this.favouriteBooth);

    if (
      (this.responsiveService.isMobile() &&
        this.currentService === LotteryService.COLLECTION &&
        !this.selectedBoothId) ||
      forceBoothSelection
    ) {
      stockBoothId = this.playShippingService.selectBooth(!forceBoothSelection).pipe(
        map(boothId => boothId || this.selectedBoothId),
        filter(boothId => !!boothId),
        tap(boothId => (this.selectedBoothId = boothId)),
      );
    }

    return stockBoothId;
  }
}
