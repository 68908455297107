/**
 * Normalize input value for US environment
 * *Also if value is copy-pasted
 *
 * @param e input Event
 * @param locale current locale
 */
export function normalizeInput(e: any, locale: string): void {
  if (locale !== 'en-US') {
    return;
  }
  const value = e.target.value;
  e.target.value = normalizeString(value, locale);
}

/**
 * Normalize string for US environment
 *
 * @param str string to normalize
 * @param locale current locale
 *
 * Examples:
 * - typing "Sánchez" -> "Sanchez"
 * - typing "O'Brian" -> "O'Brian"
 */
export function normalizeString(str: string, locale: string): string {
  if (locale !== 'en-US') {
    return;
  }
  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

/**
 *
 * @param userPhone string the phone number of the user
 * @param phonePrefix string the country prefix
 * @returns string formated number
 */
export function maskPhoneNumber(userPhone: string, phonePrefix: string): string {
  return userPhone && phonePrefix && userPhone.length > 6
    ? `+${phonePrefix}\u00A0${userPhone.slice(0, 1)}*****${userPhone.slice(6)}`
    : '';
}
