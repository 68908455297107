import {Injectable} from '@angular/core';
import {AbstractObservableDataService} from 'common';
import {PromoCard} from './promo-card';
import {Observable, startWith} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PromosCardService extends AbstractObservableDataService<
  Array<PromoCard>
> {
  setData(data?: PromoCard[]): void {
    super.setData(data ?? []);
  }

  getPromoCards(): Observable<PromoCard[]> {
    return this.getData().pipe(startWith([]));
  }
}
