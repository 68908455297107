import {
  AsapMessageHook,
  Mergeable,
  SerializableProperty,
  SerializableType,
} from 'common';

import {LotteryBooth} from '../../booth/data/lottery-booth';
import {TlContact} from '../../contacts/data/tl-contact';
import {Group} from '../../games/groups/data/group';
import {GenericTicket} from '../../games/tickets/data/generic-ticket';
import {mergeTickets, reduceTickets} from '../../games/tickets/data/operations';
import {FailedWithdrawals} from '../../money/data/failed-withdrawals';
import {MoneyActivity} from '../../money/data/money-activity';
import {
  mergeMoneyActivities,
  reduceMoneyActivities,
} from '../../money/data/operations';
import {PendingWithdrawals} from '../../money/data/pending-withdrawals';
import {Preference} from '../../preferences/data/preference';
import {UnfinishedPlay} from '../../unfinished/model/unfinished-play';
import {User} from '../../user/data/user';
import {UserExclusion} from '../../user/data/user-exclusion';
import {SelfControl} from '../endpoint/self-control';
import {PublicInfo} from '../public-info/public.info';
import {TicketCanceled} from '../../games/tickets/data/ticket-canceled';
import {SubscriptionsNotExecuted} from '../../games/tickets/data/subscriptions-not-executed';
import {PromoCard} from '../../games/upcoming/data/promo-card';

export class PrivateInfo extends PublicInfo implements Mergeable {
  @SerializableProperty(GenericTicket)
  tickets: Array<GenericTicket>;

  ticketsDeleted: Array<number>;

  @SerializableProperty(TicketCanceled, SerializableType.COLLECTION)
  ticketsCanceled: Array<TicketCanceled>;

  @SerializableProperty(SubscriptionsNotExecuted, SerializableType.OBJECT)
  subscriptionsNotExecuted: SubscriptionsNotExecuted;

  totalTickets: number;

  @SerializableProperty(MoneyActivity)
  moneyActivities: Array<MoneyActivity>;

  moneyActivitiesMaxPagination: number;

  @SerializableProperty(LotteryBooth)
  lotteryBooths: Array<LotteryBooth>;

  @SerializableProperty(User, SerializableType.OBJECT)
  user: User;

  lastUpdate: number;

  @SerializableProperty(Preference)
  preferences: Array<Preference>;

  @SerializableProperty(TlContact)
  contacts: Array<TlContact>;

  freshNews: number;

  messageHooks: Array<AsapMessageHook>;

  @SerializableProperty(Group)
  groups: Array<Group>;

  @SerializableProperty(UnfinishedPlay)
  unfinishedPlays: Array<UnfinishedPlay>;

  @SerializableProperty(PendingWithdrawals)
  pendingWithdrawals: Array<PendingWithdrawals>;

  @SerializableProperty(FailedWithdrawals)
  failedWithdrawals: Array<FailedWithdrawals>;

  @SerializableProperty(PromoCard)
  promoCards: Array<PromoCard>;

  selfControl: SelfControl;

  maxNumPenyasToPlay: number;

  static createFromBackend(obj: any) {
    let publicInfo: PublicInfo = PublicInfo.createFromBackend(obj);

    let privateInfo = new PrivateInfo();
    privateInfo.results = publicInfo.results;
    privateInfo.upcomingGames = publicInfo.upcomingGames;
    privateInfo.promoCards = publicInfo.promoCards;
    privateInfo.sentences = publicInfo.sentences;
    privateInfo.gameMetadata = publicInfo.gameMetadata;
    privateInfo.endpointInfo = publicInfo.endpointInfo;
    privateInfo.states = publicInfo.states;
    privateInfo.messageBanner = publicInfo.messageBanner;
    privateInfo.messagesBannerByState = publicInfo.messagesBannerByState;
    privateInfo.countries = publicInfo.countries;

    privateInfo.freshNews = obj.news;

    privateInfo.tickets = [];
    if (obj.boletos) {
      for (let ticket of obj.boletos) {
        privateInfo.tickets.push(GenericTicket.createFromBackend(ticket));
      }
    }

    privateInfo.ticketsDeleted = [];
    if (obj.boletosDeleted) {
      privateInfo.ticketsDeleted = obj.boletosDeleted;
    }

    privateInfo.ticketsCanceled = [];
    if (obj.boletosCancelled) {
      privateInfo.ticketsCanceled = obj.boletosCancelled.map(
        TicketCanceled.createFromBackend,
      );
    }

    privateInfo.subscriptionsNotExecuted =
      obj.abonosNotExecutedCached &&
      SubscriptionsNotExecuted.createFromBackend(obj.abonosNotExecutedCached);

    privateInfo.totalTickets = obj.totalBoletos;

    privateInfo.moneyActivitiesMaxPagination = obj.totalMovimientos;
    privateInfo.moneyActivities = [];
    if (obj.movimientos) {
      privateInfo.moneyActivities = obj.movimientos.map(move =>
        MoneyActivity.createFromBackend(move),
      );
    }

    privateInfo.lotteryBooths = [];
    if (obj.hasOwnProperty('administraciones')) {
      privateInfo.lotteryBooths = obj.administraciones.map(booth =>
        LotteryBooth.createFromBackend(booth),
      );
    }

    privateInfo.preferences = [];
    if (obj.hasOwnProperty('preferences')) {
      privateInfo.preferences = obj.preferences.preferences.map(preference =>
        Preference.createFromBackend(preference),
      );
    }

    privateInfo.contacts = [];
    if (obj.hasOwnProperty('amigos')) {
      privateInfo.contacts = obj.amigos.map(contact =>
        TlContact.createFromBackend(contact),
      );
    }

    privateInfo.messageHooks = [];
    if (obj.hasOwnProperty('mensajes')) {
      privateInfo.messageHooks = obj.mensajes.map(message =>
        AsapMessageHook.createFromBackend(message),
      );
    }

    if (obj.hasOwnProperty('userInfo')) {
      privateInfo.user = User.createFromBackend(obj.userInfo, publicInfo.countries);

      if (obj.hasOwnProperty('autocontrol')) {
        privateInfo.selfControl = obj.autocontrol;
        if (
          obj.autocontrol.selfExclusion &&
          !!obj.autocontrol.selfExclusion.durationInMonths
        ) {
          privateInfo.user.exclusion = UserExclusion.createFromBackend(
            obj.autocontrol.selfExclusion,
          );
        }
      }
    }
    privateInfo.lastUpdate = obj.fechaAllInfo;

    if (obj.hasOwnProperty('groups')) {
      privateInfo.groups = obj.groups.map(group => Group.createFromBackend(group));
    }

    if (obj.hasOwnProperty('unfinishedPlays')) {
      try {
        privateInfo.unfinishedPlays = obj.unfinishedPlays.map(play =>
          UnfinishedPlay.createFromBackend(play),
        );
      } catch (e) {
        privateInfo.unfinishedPlays = [];
      }
    }

    if (obj.hasOwnProperty('pendingWithdrawals')) {
      privateInfo.pendingWithdrawals = obj.pendingWithdrawals.map(withdrawal =>
        PendingWithdrawals.createFromBackend(withdrawal),
      );
    }

    if (obj.hasOwnProperty('failedWithdrawalsToShow')) {
      privateInfo.failedWithdrawals = obj.failedWithdrawalsToShow.map(withdrawal =>
        FailedWithdrawals.createFromBackend(withdrawal),
      );
    }

    privateInfo.maxNumPenyasToPlay = obj.maxNumPenyasToPlay;
    return privateInfo;
  }

  merge(obj: PrivateInfo) {
    for (let property in obj) {
      if (
        obj.hasOwnProperty(property) &&
        (obj[property] || obj[property] === 0) &&
        ((Array.isArray(obj[property]) &&
          (obj[property].length || property === 'groups')) ||
          !Array.isArray(obj[property])) &&
        property !== 'tickets' &&
        property !== 'moneyActivities'
      ) {
        this[property] = obj[property];
      }
    }

    this.unfinishedPlays = obj.unfinishedPlays;
    this.freshNews = obj.freshNews;

    // when banner is null instead of undefined we have to delete the old one
    if (obj.messageBanner === null) {
      this.messageBanner = null;
    }

    /**
     * If no bannerList arrives in the allInfo/v2 response,
     * it means that there are no changes from previous requests, so no front-end update is needed.
     *
     * If it arrives as an empty array,
     * it means that the banners in the panel have been deleted,
     * and therefore you have to update in front.
     *
     * If it arrives as an array with content,
     * it also needs to be updated in front.
     */
    if (obj.messagesBannerByState) {
      this.messagesBannerByState = obj.messagesBannerByState;
    }

    if (obj.tickets) {
      this.tickets = mergeTickets(this.tickets, obj.tickets);
    }

    if (obj.ticketsDeleted) {
      obj.ticketsDeleted.forEach((ticketId: number) => {
        let index = this.tickets.findIndex(t => t.id === ticketId);
        if (index >= 0) {
          this.tickets.splice(index, 1);
        }
      });
    }

    if (obj.moneyActivities) {
      this.moneyActivities = mergeMoneyActivities(
        this.moneyActivities,
        obj.moneyActivities,
      );
    }
    this.ticketsCanceled = obj.ticketsCanceled;
    this.subscriptionsNotExecuted = obj.subscriptionsNotExecuted;

    if (!!obj.promoCards) {
      this.promoCards = obj.promoCards;
    }
  }

  reduce() {
    this.tickets = reduceTickets(this.tickets);
    this.moneyActivities = reduceMoneyActivities(this.moneyActivities);
  }
}
