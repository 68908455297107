import {Injectable, NgZone} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {LocalStorage, Logger, ResponsiveService} from 'common';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {TuLoteroLocalStorageService} from './common/local-storage/tu-lotero-local-storage.service';
import {PlatformRoutesService} from './common/responsive/platform-routes.service';
import {MeasuringService} from './marketing/measuring.service';
import {TrackeablePromoService} from './promos/model/trackeable-promo-service';
import {VersionMismatchService} from './version-mismatch/version-mismatch.service';
import {defer, iif, Observable, of, throwError} from 'rxjs';
import {DeleteAllInfoInVersionService} from './all-info/delete-all-info-in-version.service';

@Injectable({providedIn: 'root'})
export class AppViewService {
  constructor(
    private tuLoteroLocalStorageService: TuLoteroLocalStorageService,
    private localStorage: LocalStorage,
    private logger: Logger,
    private measuringService: MeasuringService,
    private platformRoutes: PlatformRoutesService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private trackeablePromoService: TrackeablePromoService,
    private versionMismatchService: VersionMismatchService,
    private deleteAllInfoInVersionService: DeleteAllInfoInVersionService,
    private zone: NgZone,
  ) {
    if (!this.platformRoutes.assertRoutePlatform()) {
      this.platformRoutes.navigateAdyacentRoute('merge');
    }

    this.responsiveService.breakpointChange
      .pipe(map(() => this.responsiveService.getCurrentScreen()))
      .subscribe(() => {
        if (!this.platformRoutes.assertRoutePlatform()) {
          this.platformRoutes.navigateAdyacentRoute();
        }
      });
  }

  checkDataIntegrity() {
    let version = this.localStorage.getItem(environment.localStorageKeys.appVersion);

    const source = of(void 0).pipe(
      switchMap(() =>
        iif(
          () => !version || version !== environment.appVersion,
          this.deleteAllInfoInVersionService
            .deleteAllInfo()
            .pipe(
              switchMap((deleteAllInfo: boolean) =>
                iif(() => deleteAllInfo, this.removeAllInfo(), of(void 0)),
              ),
            ),
          of(void 0),
        ),
      ),
      switchMap(() =>
        iif(
          () => !!version && version !== environment.appVersion,
          defer(() =>
            this.versionMismatchService.runHooks(+version).pipe(
              catchError(error => {
                this.logger.error(error);
                return of(void 0);
              }),
            ),
          ),
          of(void 0),
        ),
      ),
    );

    // Update version on localStorage
    source.subscribe(() =>
      this.localStorage.setItem(
        environment.localStorageKeys.appVersion,
        environment.appVersion,
      ),
    );
  }

  initializeAnalytics() {
    if (environment.production) {
      this.zone.runOutsideAngular(() => {
        this.router.events
          .pipe(filter(e => e instanceof NavigationEnd))
          .subscribe((n: NavigationEnd) =>
            this.measuringService.sendTagManagerEvent('page_view', {
              viewUrl: n.urlAfterRedirects,
              appVersion: environment.appVersion,
            }),
          );
      });
    }
  }

  /**
   * Marketing department could redirect users to webapp register screen with
   * param 'codigo'. This code should be saved and put in the promotional code
   * box if the user start a register flow.
   */
  trackPromoCode() {
    const params = new URLSearchParams(window.location.search);
    if (params.has(environment.locale.routes.promoParam)) {
      this.trackeablePromoService.savePromocode(
        params.get(environment.locale.routes.promoParam),
      );
    }
  }

  private removeAllInfo(): Observable<void> {
    return this.tuLoteroLocalStorageService
      .removeItem(environment.localStorageKeys.allInfo)
      .pipe(
        catchError(error => {
          this.logger.error('Error when try delete allInfo', error);
          return throwError(() => error);
        }),
      );
  }
}
