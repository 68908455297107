import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, first} from 'rxjs';
import {ExclusionService} from './model/exclusion.service';
import {SpendLimits} from './model/spend-limits';

@Injectable()
export class LimitsResolver implements Resolve<SpendLimits> {
  constructor(private exclusionService: ExclusionService) {}

  resolve(): Observable<SpendLimits> {
    return this.exclusionService.getLimits().pipe(first());
  }
}
