<div class="money-bar-container" [@.disabled]="firstRender">
  <div class="money-bar__animation-wrapper" @MoneyBarAnimation *ngIf="!confirmed">
    <div class="money-bar bar">
      <tl-number-unit
        *ngIf="isNumeric(price1)"
        [ngClass]="{nomargin: nomargin || isLongPrice(price1)}"
        [value]="price1"
        [showCurrency]="false"
        [integer]="integer1"
        color="primary"
        [description]="priceDesc1">
      </tl-number-unit>

      <tl-number-unit
        *ngIf="isNumeric(price2)"
        [value]="price2"
        [showCurrency]="false"
        [integer]="integer2"
        [ngClass]="{nomargin: nomargin || isLongPrice(price2)}"
        color="secondary"
        [description]="priceDesc2">
      </tl-number-unit>

      <ng-content></ng-content>

      <div *ngIf="buttonText" class="money-bar-button">
        <button
          [type]="inForm && !confirm ? 'submit' : 'button'"
          class="btn btn-type-4 pull-right bet-price-play"
          [class.disabled]="disabledStyle"
          [disabled]="disableConfirm || loading"
          (click)="onClick()">
          {{ buttonText }}
          <i *ngIf="loading" class="icon-spin6-icon tl-icon-spin"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="money-bar__animation-wrapper" @ConfirmAnimation *ngIf="confirmed">
    <div class="money-bar confirm">
      <p *ngIf="!confirmText; else customText">
        {{ 'games.play.dialogConfirmPlay.title' | i18n }}
        <br />
        {{
          'games.play.dialogConfirmPlay.content'
            | i18nData: {amountWithCurrency: (price2 | money)}
        }}
      </p>
      <ng-template #customText>
        <p>
          <tl-translatable-text [text]="confirmText"></tl-translatable-text>
        </p>
      </ng-template>
      <div>
        <button
          type="submit"
          class="btn btn-type-4 pull-right bet-price-play"
          [disabled]="disableConfirm || loading"
          (click)="onClick()">
          {{ 'games.play.dialogConfirmPlay.buttonOk' | i18n }}
          <i *ngIf="loading" class="icon-spin6-icon tl-icon-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
