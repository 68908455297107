import {Inject, Injectable} from '@angular/core';
import {LocalStorage, Logger, ResponsiveService} from 'common';
import {Observable, of, Subscriber, switchMap, throwError} from 'rxjs';
import {environment} from '~environments/environment';
import {catchError, filter, map, take, tap} from 'rxjs/operators';
import {StatesDao} from '../states/data/states.dao';
import {AbstractGeolocationService} from './abstract-geolocation.service';
import {PositionErrors} from './geolocation-errors';

@Injectable()
export class GeolocationUsService extends AbstractGeolocationService {
  constructor(
    protected localStorage: LocalStorage,
    protected logger: Logger,
    @Inject('window') protected window: Window,
    private statesDao: StatesDao,
    private responsiveService: ResponsiveService,
  ) {
    super(localStorage, logger, window);
  }

  getCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    if (!this.isGeolocationSupported()) {
      return throwError(() => new Error('Geolocation not available'));
    }

    return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
      const success = position => {
        this.setPermissionStatus(true);
        subscriber.next(position);
        subscriber.complete();
      };
      const error = err => {
        if (err.code === PositionErrors.PERMISSION_DENIED) {
          this.setPermissionStatus(false);
        }
        subscriber.error(err);
      };

      this.window.navigator.geolocation.getCurrentPosition(
        success,
        error,
        positionOptions,
      );
    });
  }

  /**
   * Returns the first location that meets the precision with a maximum of 5 attempts.
   *
   * @param positionOptions
   */
  getBestCurrentLocation(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    if (!this.isGeolocationSupported()) {
      return throwError(() => new Error('Geolocation not available'));
    }
    const defaultOptions: PositionOptions = {
      enableHighAccuracy: environment.geolocation.highAccuracy,
      timeout: environment.geolocation.timeout,
    };

    // Accuracy Threshold for improvement
    const accuracyThreshold = 40;
    const maxAttempts = 3;
    const ti = new Date().getTime();
    return this.getGeolocation(
      positionOptions ?? defaultOptions,
      maxAttempts,
      accuracyThreshold,
      ti,
    );
  }

  watchPosition(positionOptions?: PositionOptions): Observable<GeolocationPosition> {
    this.clearWatch();

    return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
      const success = position => {
        this.setPermissionStatus(true);
        subscriber.next(position);
      };
      const error = err => {
        this.setPermissionStatus(false);
        subscriber.error(err);
      };

      if (this.watcherId) {
        this.watcherId = undefined;
      }

      this.watcher = subscriber;

      this.watcherId = this.window.navigator.geolocation.watchPosition(
        success,
        error,
        positionOptions,
      );
    });
  }

  getCurrentPosition(
    positionOptions?: PositionOptions,
  ): Observable<GeolocationPosition> {
    return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
      const success = (position: GeolocationPosition) => {
        this.setPermissionStatus(true);
        subscriber.next(position);
        subscriber.complete();
      };
      const error = (err: GeolocationPositionError) => {
        this.setPermissionStatus(false);
        subscriber.error(err);
      };

      this.window.navigator.geolocation.getCurrentPosition(
        success,
        error,
        positionOptions,
      );
    });
  }

  private getGeolocation(
    positionOptions: PositionOptions,
    maxAttempts: number,
    accuracyThreshold: number,
    initTime: number,
    attempt: number = 1,
    currentPosition?: GeolocationPosition,
  ): Observable<GeolocationPosition> {
    const ti = new Date().getTime();
    const maxGeolocationAPIAttempts = 3;
    // pedimos localizacion
    return this.getGeolocationFromApi(
      positionOptions,
      attempt,
      initTime,
      maxGeolocationAPIAttempts,
      accuracyThreshold,
      currentPosition,
    ).pipe(
      switchMap((position: GeolocationPosition) => {
        // comprobamos con backend si la localizacion es correcta
        return this.statesDao.getStateCodeByLocation({geolocation: position}).pipe(
          map(() => position),
          catchError(err => {
            this.logger.info('Geolocation StateCodeByLocation error', {
              error: err,
              attempt: attempt,
              position: {
                coords: position.coords,
                timestamp: position.timestamp,
              },
              currentPosition: currentPosition,
              time: `${new Date().getTime() - ti} ms`,
            });
            if (err.error.code === 'NotEnoughAccuracy') {
              // si no es correcta, volvemos a pedir localizacion
              if (attempt < maxAttempts) {
                return this.getGeolocation(
                  positionOptions,
                  maxAttempts,
                  accuracyThreshold,
                  initTime,
                  attempt + 1,
                  position,
                );
              } else {
                // si no es correcta y es el ultimo intento,
                // devolvemos la ultima localizacion recibida.
                // si el dispositivo es desktop,
                // usamos la localizacion de IP para obtener la localizacion de usuario
                if (this.responsiveService.isDesktop()) {
                  // Set user location based on IP

                  return this.statesDao.getUserGeolocationByIp().pipe(
                    tap(location => {
                      this.logger.info('Geolocation By IP ', {
                        location: location,
                        time: `${new Date().getTime() - ti} ms`,
                      });
                    }),
                    map(location => ({
                      coords: {
                        latitude: location.latitude,
                        longitude: location.longitude,
                        accuracy: 0,
                        altitude: 0,
                        heading: 0,
                        speed: 0,
                        altitudeAccuracy: 0,
                      },
                      timestamp: new Date().getTime(),
                    })),
                  );
                } else {
                  return of(currentPosition);
                }
              }
            } else {
              return throwError(() => err);
            }
          }),
        );
      }),
    );
  }

  private getGeolocationFromApi(
    positionOptions: PositionOptions,
    attempt: number,
    initTime: number,
    maxGeolocationAPIAttempts: number,
    accuracyThreshold: number,
    currentPosition?: GeolocationPosition,
    geolocationAPIAttempt: number = 1,
  ): Observable<GeolocationPosition> {
    const ti = new Date().getTime();
    return this.getCurrentPosition(positionOptions).pipe(
      filter((position: GeolocationPosition) => !!position),
      take(1),
      tap((geolocationPosition: GeolocationPosition) => {
        this.logger.debug('Time to get geolocation position', {
          attempt: attempt,
          geolocationAPIAttempt: geolocationAPIAttempt,
          position: {
            coords: geolocationPosition.coords,
            timestamp: geolocationPosition.timestamp,
          },
          currentPosition: currentPosition,
          time: `${new Date().getTime() - ti} ms`,
          timeFromInit: `${new Date().getTime() - initTime} ms`,
        });
      }),
      switchMap((position: GeolocationPosition) => {
        if (
          !!currentPosition &&
          geolocationAPIAttempt < maxGeolocationAPIAttempts &&
          !this.hasAccuracyImproved(
            currentPosition?.coords.accuracy,
            position.coords.accuracy,
            accuracyThreshold,
          )
        ) {
          this.logger.info('Geolocation API attempt without improvement', {
            attempt: attempt,
            geolocationAPIAttempt: geolocationAPIAttempt,
            position: {
              coords: position.coords,
              timestamp: position.timestamp,
            },
            currentPosition: currentPosition,
            time: `${new Date().getTime() - ti} ms`,
            timeFromInit: `${new Date().getTime() - initTime} ms`,
          });
          return this.getGeolocationFromApi(
            positionOptions,
            attempt,
            initTime,
            maxGeolocationAPIAttempts,
            accuracyThreshold,
            currentPosition,
            geolocationAPIAttempt + 1,
          );
        }
        return of(position);
      }),
      catchError((err: GeolocationPositionError) => {
        this.logger.error('Geolocation error', new Error().stack, err);
        // Timeout error
        if (err.code === PositionErrors.TIMEOUT) {
          if (currentPosition) {
            // avoid reset permission for timeout errors
            this.setPermissionStatus(true);
            return of(currentPosition);
          } else {
            if (geolocationAPIAttempt < maxGeolocationAPIAttempts) {
              return this.getGeolocationFromApi(
                positionOptions,
                attempt,
                initTime,
                maxGeolocationAPIAttempts,
                accuracyThreshold,
                currentPosition,
                geolocationAPIAttempt + 1,
              );
            } else {
              return this.statesDao.getUserGeolocationByIp().pipe(
                tap(location => {
                  this.logger.info('Geolocation By IP caused by position timeout ', {
                    location: location,
                    time: `${new Date().getTime() - ti} ms`,
                  });
                }),
                map(location => ({
                  coords: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    accuracy: 0,
                    altitude: 0,
                    heading: 0,
                    speed: 0,
                    altitudeAccuracy: 0,
                  },
                  timestamp: new Date().getTime(),
                })),
              );
            }
          }
        }
        if (err.code === PositionErrors.POSITION_UNAVAILABLE) {
          // Position unavailable -> Get geolocation by IP
          return this.statesDao.getUserGeolocationByIp().pipe(
            tap(location => {
              this.logger.info('Geolocation By IP caused by position unavailable ', {
                location: location,
                time: `${new Date().getTime() - ti} ms`,
              });
            }),
            map(location => ({
              coords: {
                latitude: location.latitude,
                longitude: location.longitude,
                accuracy: 0,
                altitude: 0,
                heading: 0,
                speed: 0,
                altitudeAccuracy: 0,
              },
              timestamp: new Date().getTime(),
            })),
          );
        }
        return throwError(
          () => new Error(`Geolocation error with code: ${err.code}`),
          // Error code -> 1 : Permission denied, 2: Position unavailable, 3: Timeout
        );
      }),
    );
  }

  private hasAccuracyImproved(
    oldAccuracy: number,
    newAccuracy: number,
    accuracyThreshold: number,
  ): boolean {
    const improvement = oldAccuracy - newAccuracy;
    // Calculate the percentage improvement
    const improvementPercentage = (improvement / oldAccuracy) * 100;

    // Check if the improvement is at least accuracyThreshold
    return improvementPercentage >= accuracyThreshold;
  }
}
