import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeolocationBlockedModalComponent} from './geolocation-blocked-modal.component';
import {ModalDialogModule, TranslatableTextModule} from 'common';

@NgModule({
  declarations: [GeolocationBlockedModalComponent],
  imports: [CommonModule, ModalDialogModule, TranslatableTextModule],
})
export class GeolocationBlockedModalModule {}
