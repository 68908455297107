import {NgModule} from '@angular/core';

import {TimestampToDateFromNowPipe} from './timestamp-to-date-from-now.pipe';
import {TimestampToDateTimePipe} from './timestamp-to-date-time.pipe';
import {TimestampToDatePipe} from './timestamp-to-date.pipe';
import {TimestampToDayMonthNameYearPipe} from './timestamp-to-day-month-name-year.pipe';
import {TimestampToDayMonthPipe} from './timestamp-to-day-month.pipe';
import {TimestampToDayNameDatePipe} from './timestamp-to-day-name-date.pipe';
import {TimestampToDayNameMonthPipe} from './timestamp-to-day-name-month.pipe';
import {TimestampToDayNamePipe} from './timestamp-to-day-name.pipe';
import {TimestampToDayPipe} from './timestamp-to-day.pipe';
import {TimestampToPastDatePipe} from './timestamp-to-past-date.pipe';
import {TimestampToShortDateTimePipe} from './timestamp-to-short-datetime.pipe';
import {TimestampToShortMonthPipe} from './timestamp-to-short-month.pipe';
import {TimestampToTimePipe} from './timestamp-to-time.pipe';
import {TimestampToDayMonthFromNowPipe} from './timestamp-to-day-month-from-now.pipe';
import {TimestampToDayNameLongPipe} from './timestamp-to-day-name-long.pipe';

@NgModule({
  exports: [
    TimestampToDateFromNowPipe,
    TimestampToDatePipe,
    TimestampToDateTimePipe,
    TimestampToDayMonthNameYearPipe,
    TimestampToDayMonthPipe,
    TimestampToDayNameDatePipe,
    TimestampToDayNameLongPipe,
    TimestampToDayNameMonthPipe,
    TimestampToDayNamePipe,
    TimestampToDayPipe,
    TimestampToPastDatePipe,
    TimestampToShortDateTimePipe,
    TimestampToShortMonthPipe,
    TimestampToTimePipe,
    TimestampToDayMonthFromNowPipe,
  ],
  declarations: [
    TimestampToDateFromNowPipe,
    TimestampToDatePipe,
    TimestampToDateTimePipe,
    TimestampToDayMonthNameYearPipe,
    TimestampToDayMonthPipe,
    TimestampToDayNameDatePipe,
    TimestampToDayNameLongPipe,
    TimestampToDayNameMonthPipe,
    TimestampToDayNamePipe,
    TimestampToDayPipe,
    TimestampToPastDatePipe,
    TimestampToShortDateTimePipe,
    TimestampToShortMonthPipe,
    TimestampToTimePipe,
    TimestampToDayMonthFromNowPipe,
  ],
})
export class DatePipesModule {}
