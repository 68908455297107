import {AlphanumericGameTypeMetadata} from './alphanumeric-game-type-metadata';
import {BooleanGameTypeMetadata} from './boolean-game-type-metadata';
import {DigitsGameTypeMetadata} from './digits-game-type-metadata';
import {NumbersGameTypeMetadata} from './numbers-game-type-metadata';
import {SelectionGameTypeMetadata} from './selection-game-type-metadata';

export const GAME_TYPE_METADATA_RESOLVER = {
  NUMBER: NumbersGameTypeMetadata,
  REPETIBLE_NUMBER: NumbersGameTypeMetadata,
  SELECTION: SelectionGameTypeMetadata,
  GUESS_DIGIT: DigitsGameTypeMetadata,
  ALPHANUMERIC: AlphanumericGameTypeMetadata,
  BOOLEAN: BooleanGameTypeMetadata,
  BOOLEAN_ARRAY: BooleanGameTypeMetadata,
  // TODO: Check when Quinigol is implemented WEB-630
  SELECTION_HOME_AWAY: SelectionGameTypeMetadata,
};
