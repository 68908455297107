import {Injectable} from '@angular/core';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs';

import {User} from '../user/data/user';
import {UserService} from '../user/data/user.service';
import {LocaleService, TranslationsService} from 'common';
import {PrivateInfoService} from '../backend/private-info/private-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserLocaleListenerService {
  constructor(
    private userService: UserService,
    private localeService: LocaleService,
    private translationsService: TranslationsService,
    private privateInfoService: PrivateInfoService,
  ) {}

  /**
   * Initialize listener
   */
  init(): void {
    this.userService
      .getData()
      .pipe(
        filter((user: User) => !!user),
        map((user: User) => user?.languageCode),
        filter((localeCode: string) => !!localeCode),
        distinctUntilChanged(),
        map((localeCode: string) => {
          if (this.localeService.getLocale() === localeCode) {
            return false;
          }
          this.localeService.setLocale(localeCode);
          return true;
        }),
        filter((reload: boolean) => reload),
        switchMap(() => this.translationsService.load()),
        switchMap(() => this.privateInfoService.load()),
      )
      .subscribe();
  }
}
