import {Inject, Injectable} from '@angular/core';
import {from, Observable, of, throwError, zip} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {Logger} from '../logger/logger';

import {Camera} from './camera';
import {ENVIRONMENT} from '../environment/environment-token';
import {CameraType} from './camera-type';
import {DeviceService} from '../device/device.service';
import {ResponsiveService} from '../responsive/responsive.service';
import {DeviceOs} from '../device/deviceOs';

@Injectable({providedIn: 'root'})
export class CameraService {
  devices: Array<MediaDeviceInfo>;

  cameraType: CameraType;

  constructor(
    private deviceService: DeviceService,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private logger: Logger,
    private responsiveService: ResponsiveService,
    @Inject('window') private window: Window,
  ) {}

  getCameraSelector(
    type: CameraType,
    compare: (
      camera1: Camera,
      camera2: Camera,
      data: {
        logger: Logger;
        cameraType: CameraType;
        devices: Array<MediaDeviceInfo>;
      },
    ) => Camera,
  ): Observable<boolean | MediaTrackConstraintSet> {
    this.cameraType = type;
    return zip(
      from(this.window.navigator.mediaDevices.enumerateDevices()),
      from(navigator.permissions.query({name: 'camera' as PermissionName})),
    ).pipe(
      switchMap(([allDevices, permissionState]) => {
        const videoDevices = allDevices.filter(
          device => device.kind === 'videoinput',
        );
        if (
          permissionState.state === 'granted' &&
          videoDevices.some(dev => !dev.deviceId)
        ) {
          this.logger.warn('WEB-2092: Devices found', {
            devices: videoDevices,
          });
        }

        this.devices = videoDevices;
        const savedDeviceId = this.getSaveDeviceId(type);
        const savedDevice = this.getDeviceById(savedDeviceId, videoDevices);
        if (!!savedDeviceId && !savedDevice) {
          this.deleteSaveDeviceId(type);
        }

        if (this.devices.length > 0) {
          if (
            this.responsiveService.isDesktop() ||
            (this.deviceService.getOS() !== DeviceOs.ANDROID &&
              this.deviceService.getOS() !== DeviceOs.IOS)
          ) {
            return of(true);
          } else if (this.deviceService.getOS() === DeviceOs.IOS) {
            // En safari da igual los sensores que tenga la camara solo devuelve uno
            // frontal y uno trasero. La unica manera de que escoja uno u otro es asi,
            // no puede ir por deviceId porque enumerateDevices solo devuelve el
            // frontal.
            const config =
              type === CameraType.FRONT
                ? {facingMode: 'user'}
                : {facingMode: {exact: 'environment'}};
            return of(config);
          } else if (this.deviceService.isFirefox) {
            // Bug AbortError when getUserMedia with deviceId
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1805719
            const config = {
              facingMode: {
                exact: type === CameraType.FRONT ? 'user' : 'environment',
              },
            };
            return of(config);
          } else if (!!savedDevice) {
            // Si hemos guardado uno lo usamos
            return of({deviceId: savedDevice.deviceId});
          } else {
            return this.selectCamera(undefined, videoDevices.clone(), compare).pipe(
              map((selectedCamera: Camera) => {
                if (!!selectedCamera) {
                  const deviceId = selectedCamera.device.deviceId;
                  this.saveDeviceId(type, deviceId);
                  return {deviceId: deviceId};
                } else {
                  // No camera selected but there is devices
                  this.logger.warn(
                    'CameraService: No device selected but there is devices',
                    {
                      devices: this.devices,
                    },
                  );
                  const config =
                    type === CameraType.FRONT
                      ? {facingMode: 'user'}
                      : {facingMode: {exact: 'environment'}};
                  return config;
                }
              }),
            );
          }
        } else {
          this.logger.error('CameraService: There is not video cameras', undefined, {
            allDevices: allDevices,
          });
          throwError(() => new Error('There is not video cameras'));
        }
      }),
    );
  }

  private selectCamera(
    currentCamera: Camera,
    remainingDevices: Array<MediaDeviceInfo>,
    compare: (
      camera1: Camera,
      camera2: Camera,
      data: {
        logger: Logger;
        cameraType: CameraType;
        devices: Array<MediaDeviceInfo>;
      },
    ) => Camera,
  ): Observable<Camera> {
    if (!remainingDevices || remainingDevices.length === 0) {
      // If not device selected (for example choose torch when none have) then select last
      const useCamera = !!currentCamera
        ? currentCamera
        : {
            device: this.devices[this.devices.length - 1],
          };
      return of(useCamera);
    } else {
      const device = remainingDevices.shift();
      return this.getCamera(device).pipe(
        switchMap((camera: Camera) => {
          const bestCamera = compare(currentCamera, camera, {
            logger: this.logger,
            cameraType: this.cameraType,
            devices: this.devices,
          });
          return this.selectCamera(bestCamera, remainingDevices, compare);
        }),
      );
    }
  }

  private getCamera(device: MediaDeviceInfo): Observable<Camera> {
    const config = {
      video: {deviceId: device.deviceId},
      audio: false,
    };
    return from(this.window.navigator.mediaDevices.getUserMedia(config)).pipe(
      map((stream: MediaStream) => {
        const tracks = stream.getVideoTracks();

        // Log info
        if (!!tracks && tracks.length > 1) {
          this.logger.warn('CameraService: There are two capabilities', {
            tracks: tracks,
            device: device,
          });
        }

        const cameraTracks = tracks
          .map((track: MediaStreamTrack) => {
            const capabilities = track.getCapabilities
              ? track.getCapabilities()
              : undefined;
            track.stop();
            return {
              track: track,
              capabilities: capabilities,
            };
          })
          .filter(capabilities => !!capabilities);

        return {
          device: device,
          stream: stream,
          tracks: cameraTracks,
        };
      }),
      catchError(error => {
        this.logger.error('CameraService: Error getUserMedia', error, {
          config: config,
          error: error,
        });
        return throwError(() => error);
      }),
    );
  }

  private getDeviceById(
    id: string,
    devices: Array<MediaDeviceInfo>,
  ): MediaDeviceInfo {
    if (!id) {
      return undefined;
    }
    return devices.find(currentDevice => currentDevice.deviceId === id);
  }

  private deleteSaveDeviceId(type: CameraType) {
    localStorage.removeItem(
      type === CameraType.FRONT
        ? this.environment.localStorageKeys.cameraDevideIdFront
        : this.environment.localStorageKeys.cameraDevideIdBack,
    );
  }

  private getSaveDeviceId(type: CameraType): string {
    return localStorage.getItem(
      type === CameraType.FRONT
        ? this.environment.localStorageKeys.cameraDevideIdFront
        : this.environment.localStorageKeys.cameraDevideIdBack,
    );
  }

  private saveDeviceId(type: CameraType, id: string): void {
    localStorage.setItem(
      type === CameraType.FRONT
        ? this.environment.localStorageKeys.cameraDevideIdFront
        : this.environment.localStorageKeys.cameraDevideIdBack,
      id,
    );
  }
}
