<div
  class="arrow top"
  [class.disabled-button]="disabledButtons"
  (click)="previous()">
  <i class="icon-up-open-icon"></i>
</div>
<div
  class="wheel-viewport"
  [style.height]="viewportHeight"
  [class.disabled]="disabled">
  <div #wheel class="wheel-values">
    <div
      #firstItem
      *ngFor="let i of 5 | arrayFromNumber"
      class="wheel-item"
      [class.center-item]="i === 2"
      [class.default-template]="!itemTemplate">
      <ng-template
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: virtualSet[virtualIndex(displayIndex + i - 2)]
        }">
      </ng-template>
      <ng-container *ngIf="!itemTemplate">
        {{ virtualSet[virtualIndex(displayIndex + i - 2)] }}
      </ng-container>
    </div>
  </div>
  <div class="line top" [style.top]="childHeight * CORNER_RATIO + 'px'">&nbsp;</div>
  <div class="line bottom-line" [style.bottom]="childHeight * CORNER_RATIO + 'px'">
    &nbsp;
  </div>
</div>
<div class="arrow bottom" [class.disabled-button]="disabledButtons" (click)="next()">
  <i class="icon-down-open-icon"></i>
</div>
