export enum GameId {
  LOTERIA_NACIONAL = 'LOTERIA_NACIONAL',
  BONOLOTO = 'BONOLOTO',
  EURODREAMS = 'EURODREAMS',
  QUINIELA = 'QUINIELA',
  CRUZ_ROJA = 'CRUZ_ROJA',
  EUROMILLONES = 'EUROMILLONES',
  PRIMITIVA = 'PRIMITIVA',
  GORDO_PRIMITIVA = 'GORDO_PRIMITIVA',
  LOTARIA_CLASSICA = 'LOTARIA_CLASSICA',
  LOTARIA_POPULAR = 'LOTARIA_POPULAR',
  TOTOBOLA = 'TOTOBOLA',
  TOTOBOLA_EXTRA = 'TOTOBOLA_EXTRA',

  // MX
  CHISPAZO = 'CHISPAZO',
  TRIS = 'TRIS',
  PROGOL = 'PROGOL',
  PROTOUCH = 'PROTOUCH_PLAY',
  PROGOL_MS = 'PROGOL_MEDIA',

  // US
  COLORADO_LOTTO_PLUS = 'COLORADO_LOTTO_PLUS',
  COLORADO_POWERBALL = 'COLORADO_POWERBALL',
  COLORADO_MEGAMILLIONS = 'COLORADO_MEGAMILLIONS',
  COLORADO_PICK_3 = 'COLORADO_PICK_3',

  MINNESOTA_POWERBALL = 'MINNESOTA_POWERBALL',
  MINNESOTA_MEGAMILLIONS = 'MINNESOTA_MEGAMILLIONS',
  MINNESOTA_GOPHER_5 = 'MINNESOTA_GOPHER_5',
  MINNESOTA_LOTTO_AMERICA = 'MINNESOTA_LOTTO_AMERICA',
  MINNESOTA_NORTH_5 = 'MINNESOTA_NORTH_5',
  MINNESOTA_PICK_3 = 'MINNESOTA_PICK_3',

  NEW_YORK_POWERBALL = 'NEW_YORK_POWERBALL',
  NEW_YORK_MEGAMILLIONS = 'NEW_YORK_MEGAMILLIONS',
  NEW_YORK_LOTTO = 'NEW_YORK_LOTTO',
  NEW_YORK_TAKE_5 = 'NEW_YORK_TAKE_5',
  NEW_YORK_NUMBERS = 'NEW_YORK_NUMBERS',
  NEW_YORK_WIN_4 = 'NEW_YORK_WIN_4',

  NEW_HAMPSHIRE_POWERBALL = 'NEW_HAMPSHIRE_POWERBALL',
  NEW_HAMPSHIRE_MEGAMILLIONS = 'NEW_HAMPSHIRE_MEGAMILLIONS',

  TEXAS_POWERBALL = 'TEXAS_POWERBALL',
  TEXAS_MEGAMILLIONS = 'TEXAS_MEGAMILLIONS',
  TEXAS_JUST_THE_JACKPOT = 'TEXAS_JUST_THE_JACKPOT',
  TEXAS_LOTTO_TEXAS = 'TEXAS_LOTTO_TEXAS',
  TEXAS_TWO_STEP = 'TEXAS_TWO_STEP',
  TEXAS_PICK_3 = 'TEXAS_PICK_3',
  TEXAS_DAILY_4 = 'TEXAS_DAILY_4',
}
