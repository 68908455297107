import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import {StringCollection, TranslatableText} from '../translatable-text';
import {Router} from '@angular/router';
import {UriMapper} from '../../uri/uri-mapper';
import {Logger} from '../../logger/logger';

@Component({
  selector: 'tl-translatable-text',
  templateUrl: './translatable-text.component.html',
  styleUrls: ['./translatable-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslatableTextComponent implements AfterViewInit, OnChanges {
  @Input()
  text: string | TranslatableText = '';

  @Input()
  plural?: number;

  @Input()
  data?: StringCollection;
  @Input()
  dataZero?: StringCollection;
  @Input()
  dataOne?: StringCollection;
  @Input()
  dataOther?: StringCollection;

  finalKey = typeof this.text === 'string' ? '' : this.text.key;
  finalData = typeof this.text === 'string' ? undefined : this.text.keyData;

  @Output()
  handleInternalLink = new EventEmitter<string>();

  constructor(
    private logger: Logger,
    private router: Router,
    private ref: ElementRef,
    private uriMapper: UriMapper,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setTextObject();

    if (this.text != null && typeof this.text !== 'string') {
      if (this.text.plural == null) {
        this.finalData = this.text.keyData;
        this.finalKey = this.text.key;
      } else {
        switch (this.text.plural) {
          case 0:
            this.finalData = {
              ...this.text.keyData,
              ...this.text.dataZero,
            };
            this.finalKey = this.text.key.concat('.zero');
            break;
          case 1:
            this.finalData = {
              ...this.text.keyData,
              ...this.text.dataOne,
            };
            this.finalKey = this.text.key.concat('.one');
            break;
          default:
            this.finalData = {
              ...this.text.keyData,
              ...this.text.dataOther,
            };
            this.finalKey = this.text.key.concat('.other');
            break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    let anchors = [...this.ref.nativeElement.querySelectorAll('a')];
    anchors = anchors
      .filter(a => a.hasAttribute('data-href'))
      .filter(a => this.uriMapper.isInternalRoute(a.getAttribute('data-href')));
    anchors.forEach(a =>
      a.addEventListener('click', e => {
        e.preventDefault();
        if (this.handleInternalLink.observed) {
          this.handleInternalLink.emit(a.getAttribute('data-href'));
        } else {
          this.manageNavigationDestination(a.href);
        }
      }),
    );
  }

  private setTextObject() {
    if (this.dataZero || this.dataOne || this.dataOther) {
      if (typeof this.text === 'string') {
        this.logger.error(
          // eslint-disable-next-line max-len
          'TranslatableText: text is a string, but plural data bindings are provided. Plural bindings will be ignored.',
          new Error().stack,
          {
            text: JSON.stringify(this.text),
            plural: this.plural,
            keyData: this.data,
            dataZero: this.dataZero,
            dataOne: this.dataOne,
            dataOther: this.dataOther,
          },
        );
      } else if (this.text.dataZero || this.text.dataOne || this.text.dataOne) {
        this.logger.error(
          // eslint-disable-next-line max-len
          'TranslatableText: text object has plural info, but plural data bindings are provided, bindings will take precedence.',
          new Error().stack,
          {
            text: JSON.stringify(this.text),
            plural: this.plural,
            keyData: this.data,
            dataZero: this.dataZero,
            dataOne: this.dataOne,
            dataOther: this.dataOther,
          },
        );
      }
    }

    if (this.text != null && typeof this.text !== 'string') {
      this.text = {
        key: this.text.key,
        plural: this.plural ?? this.text.plural,
        keyData: this.data ?? this.text.keyData,
        dataZero: this.dataZero ?? this.text.dataZero,
        dataOne: this.dataOne ?? this.text.dataOne,
        dataOther: this.dataOther ?? this.text.dataOther,
      };
    }

    if (
      this.text != null &&
      typeof this.text !== 'string' &&
      this.text.plural == null &&
      (this.text.dataOne || this.text.dataOther || this.text.dataZero)
    ) {
      this.logger.error(
        // eslint-disable-next-line max-len
        'TranslatableText: plural data bindings/object should only used when plural is true. Plural information will be ignored.',
        new Error().stack,
        {
          text: JSON.stringify(this.text),
          plural: this.plural,
          keyData: this.data,
          dataZero: this.dataZero,
          dataOne: this.dataOne,
          dataOther: this.dataOther,
        },
      );
    }
  }

  private manageNavigationDestination(urlString: string) {
    const url = new URL(urlString);
    this.router.navigateByUrl(url.pathname);
  }
}
